import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDecodeToken } from "../../hooks/useDecodeToken";
import { useFetch } from "../../hooks/useFetch";
import toast, { Toaster } from "react-hot-toast";
import styles from "../../styles/MemberDetail.module.scss";
import { IoReturnUpBack } from "react-icons/io5";
import { LiaHomeSolid } from "react-icons/lia";

interface Order {
  type: string;
  createdAt: string;
}

function MemberDetail() {
  const userInfo = useDecodeToken();
  const applicationId = useParams();
  const navigate = useNavigate();

  const [memberInfo, setMemberInfo] = useState<any>({});
  const [memberSubsInfo, setMemberSubsInfo] = useState<any>({});
  const joinDate = new Date(memberInfo.createdAt).toDateString();

  useEffect(() => {
    if (userInfo) {
      const GetApplyMentor = async () => {
        try {
          const res = await useFetch(
            `${process.env.REACT_APP_STRAPI}/users/${applicationId.id}?populate[0]=User_profile.Subject&populate[1]=User_profile.Profession&populate[2]=User_profile.User_goals&populate[3]=User_profile.User_topics&populate[4]=Orders`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.jwtToken}`,
              },
            }
          );

          if (!res.error) {
            setMemberInfo(res);
          } else {
            toast.error(res.error.message);
          }
        } catch (e) {
          console.error(e);
        }
      };
      GetApplyMentor();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId]);

  useEffect(() => {
    if (memberInfo.Orders) {
      const latestSubs: Order[] = memberInfo.Orders.filter(
        (order: { type: string }) => order.type === "subscription"
      ).sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      setMemberSubsInfo(latestSubs[0]);
    } else {
      setMemberSubsInfo(null);
    }
  }, [memberInfo]);

  const handleResetPassword = (email: string) => {
    if (userInfo) {
      const ResetPassword = async () => {
        try {
          const res = await useFetch(`${process.env.REACT_APP_STRAPI}/auth/forgot-password`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.jwtToken}`,
            },
            body: JSON.stringify({
              email: email,
            }),
          });

          if (res.error) {
            throw new Error(res.error.message);
          } else {
            toast.success("重設電郵已寄出！");
          }
        } catch (error) {
          console.error(error);
        }
      };
      ResetPassword();
    }
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />

      {memberInfo.id && (
        <div className={styles.memberDetail}>
          <div className={styles.header}>
            <div onClick={() => navigate(-1)}>
              <IoReturnUpBack />
            </div>
            <div onClick={() => navigate("/")}>
              <LiaHomeSolid />
            </div>
          </div>

          <div className={styles.subTitle}>
            {memberInfo.User_profile.first_name} {memberInfo.User_profile.last_name}
          </div>

          <div className={styles.total}>
            <div className={styles.half}>
              <div className={styles.block}>
                <div className={styles.subTitle}>站內資料</div>

                <div className={styles.section}>
                  <div className={styles.item}>
                    用戶名稱 <span>{memberInfo.username}</span>
                  </div>
                  <div className={styles.item}>
                    註冊日期 <span>{joinDate}</span>
                  </div>
                  <div className={styles.item}>
                    訂閱情況{" "}
                    <span>
                      {memberSubsInfo ? <>{memberSubsInfo.subscription_name}</> : <>沒有訂閱</>}
                    </span>
                  </div>
                  <div className={styles.item}>
                    Stripe ID{" "}
                    <span>
                      {memberInfo.stripe_customer_id
                        ? `${memberInfo.stripe_customer_id}`
                        : "尚未擁有Stripe ID"}
                    </span>
                  </div>
                  <div className={styles.item}>
                    是否為導師 <span>{memberInfo.is_mentor ? "是" : "否"}</span>
                  </div>
                </div>
              </div>

              <div className={styles.block}>
                <div className={styles.subTitle}>用戶資料</div>

                <div className={styles.section}>
                  <div className={styles.item}>
                    暱稱 <span>{memberInfo.User_profile.nick_name}</span>
                  </div>
                  <div className={styles.item}>
                    性別 <span>{memberInfo.User_profile.gender === "male" ? "男" : "女"}</span>
                  </div>
                  <div className={styles.item}>
                    生日{" "}
                    <span>
                      {memberInfo.User_profile.birthday
                        ? memberInfo.User_profile.birthday
                        : "未填寫"}
                    </span>
                  </div>
                  <div className={styles.item}>
                    電郵 <span>{memberInfo.email}</span>
                  </div>
                  <div className={styles.item}>
                    電話{" "}
                    <span>{memberInfo.phone ? `${memberInfo.phone}` : "尚未進行電話認證"}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.half}>
              <div className={styles.block}>
                <div className={styles.subTitle}>個人發展</div>

                <div className={styles.section}>
                  <div className={styles.item}>
                    教育程度 <span>{memberInfo.User_profile.education_level}</span>
                  </div>
                  <div className={styles.item}>
                    學術領域 <span>{memberInfo.User_profile.Subject.name}</span>
                  </div>
                  <div className={styles.item}>
                    行業 <span>{memberInfo.User_profile.Profession.name}</span>
                  </div>
                  <div className={styles.item}>
                    職級/職位 <span>{memberInfo.User_profile.job_title}</span>
                  </div>
                </div>
              </div>

              <div className={styles.block}>
                <div className={styles.subTitle}>相關興趣</div>

                <div className={styles.section}>
                  <div className={styles.item}>
                    <div className={styles.target}>
                      學習目標{" "}
                      <div className={styles.aim}>
                        {memberInfo.User_profile.User_goals.map((goal: any) => (
                          <span key={goal.id}>{goal.name}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.target}>
                      有興趣的主題{" "}
                      <div className={styles.aim}>
                        {memberInfo.User_profile.User_topics.map((topic: any) => (
                          <span key={topic.id}>{topic.name}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.block}>
                <div className={styles.subTitle}>付款情況</div>

                <div className={styles.aim}>
                  {memberInfo.Orders.filter((info: any) => info.type !== "subscription").map(
                    (info: any) => (
                      <div key={info.id} className={styles.payment}>
                        <div>{new Date(info.createdAt).toDateString()}</div>
                        <div>{info.type.charAt(0).toUpperCase() + info.type.slice(1)}</div>
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className={styles.block}>
                <div className={styles.subTitle}>重設密碼</div>
                <div className={styles.target}>
                  <div className={styles.aim}>
                    <span onClick={() => handleResetPassword(memberInfo.email)}>發送電郵</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MemberDetail;
