import React from "react";
import styles from "../../styles/MemberInfo.module.scss";
import { useNavigate } from "react-router-dom";
import { IoReturnUpBack } from "react-icons/io5";
import { LiaHomeSolid } from "react-icons/lia";
import PlatformNotice from "../../components/platformConfig/PlatformNotice";

function PlatformNoticePage() {
  const navigate = useNavigate();

  return (
    <div className={styles.memberInfo}>
      <div className={styles.header}>
        <div onClick={() => navigate(-1)}>
          <IoReturnUpBack />
        </div>
        <div onClick={() => navigate("/")}>
          <LiaHomeSolid />
        </div>
      </div>
      <PlatformNotice />
    </div>
  );
}

export default PlatformNoticePage;
