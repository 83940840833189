import { useNavigate } from "react-router";
import styles from "../styles/Landing.module.scss";
import { BiUserCheck } from "react-icons/bi";
import { BiUserPin } from "react-icons/bi";
import { VscBookmark } from "react-icons/vsc";
import { GrConfigure } from "react-icons/gr";

function Landing() {
  const navigate = useNavigate();

  return (
    <div className={styles.landing}>
      <div className={styles.service}>
        <div className={styles.card} onClick={() => navigate("/applyMentor")}>
          <div className={styles.icon}>
            <BiUserCheck />
          </div>

          <span>Approve Mentor Application</span>
        </div>

        <div className={styles.card} onClick={() => navigate("/memberInfo")}>
          <div className={styles.icon}>
            <BiUserPin />
          </div>

          <span>Check Member Information</span>
        </div>

        <div className={styles.card} onClick={() => navigate("/newRegistration")}>
          <div className={styles.icon}>
            <VscBookmark />
          </div>

          <span>Check New Registration</span>
        </div>

        <div className={styles.card} onClick={() => navigate("/platformConfig")}>
          <div className={styles.icon}>
            <GrConfigure />
          </div>

          <span>Platform Config</span>
        </div>
      </div>
    </div>
  );
}

export default Landing;
