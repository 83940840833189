import React from "react";
import { MdOutlineDelete } from "react-icons/md";
import styles from "../../styles/ApplyDetail.module.scss";
import { useDecodeToken } from "../../hooks/useDecodeToken";
import { useFetch } from "../../hooks/useFetch";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function MentorWorkExp({ workExp, popUp }: any) {
  const userInfo = useDecodeToken();
  const navigate = useNavigate();

  const handleDelete = (id: number) => {
    if (userInfo) {
      const UpdateMentorInfo = async () => {
        try {
          const res = await useFetch(`${process.env.REACT_APP_STRAPI}/mentor-experiences/${id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.jwtToken}`,
            },
          });

          if (!res.error) {
            toast.success("已刪除！");
            navigate(-1);
          }
        } catch (e) {
          console.error(e);
        }
      };
      UpdateMentorInfo();
    }
  };

  const renderWorkExp = () => {
    return (
      <div className={styles.expList}>
        {workExp
          .filter((exp: any) => exp.attributes.type === "work")
          .sort((x: any, y: any) => {
            const eYA = x.attributes.end_year;
            const eYB = y.attributes.end_year;

            if (eYA === 0 && eYB !== 0) return -1;
            if (eYA !== 0 && eYB === 0) return 1;
            if (eYA !== eYB) return eYB - eYA;

            const eMA = x.attributes.end_month;
            const eMB = y.attributes.end_month;
            if (eMA !== eMB) return eMB - eMA;

            const sYA = x.attributes.start_year;
            const sYB = y.attributes.start_year;
            if (sYA !== sYB) return sYB - sYA;

            const sMA = x.attributes.start_month;
            const sMB = y.attributes.start_month;
            return sMB - sMA;
          })
          .map((exp: any) => (
            <div className={styles.item} key={exp.id}>
              <div className={styles.name}>
                <span className={styles.subTitle}>公司名稱</span>
                <span>{exp.attributes.org_name}</span>

                <div>
                  <span className={styles.subTitle}>職位 / 頭銜 / 機構</span>
                  <span>{exp.attributes.title_name}</span>
                </div>
              </div>

              <div className={styles.time}>
                {popUp ? (
                  <></>
                ) : (
                  <div onClick={() => handleDelete(exp.id)}>
                    <MdOutlineDelete />
                  </div>
                )}

                <div>
                  {exp.attributes.start_month} 月 {exp.attributes.start_year}
                  {" - "}
                  {exp.attributes.end_month === 0 && exp.attributes.end_year === 0 ? (
                    <>現在</>
                  ) : (
                    <>
                      {exp.attributes.end_month} 月 {exp.attributes.end_year}
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  return <>{renderWorkExp()}</>;
}

export default MentorWorkExp;
