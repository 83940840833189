import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import PrivateRoute from "./redux/PrivateRoute";
import LoginPage from "./pages/auth/login";
import LandingPage from "./pages/landing";
import NotFound from "./pages/notFound";
import ApplyMentorPage from "./pages/applyMentor/applyMentor";
import ApplyDetailPage from "./pages/applyMentor/applyDetail";
import MemberInfoPage from "./pages/memberInfo/memberInfo";
import MemberDetailPage from "./pages/memberInfo/memberDetail";
import NewRegistrationPage from "./pages/newRegistration/newRegistration";
import PlatformConfigPage from "./pages/platformConfig/platformConfig";
import PlatformNoticePage from "./pages/platformConfig/platformNotice";

function App() {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState<any>(null);

  useEffect(() => {
    setIsAdmin(localStorage.getItem("isAdmin"));

    if (isAdmin === null) {
      navigate("");
    } else if (isAdmin === "false") {
      navigate("/NotFound");
    }
  }, [isAdmin, navigate]);

  return (
    <Routes>
      <Route path="" element={<LoginPage />} />

      <Route path="" element={<PrivateRoute />}>
        {isAdmin === "true" && (
          <>
            <Route path="/" element={<LandingPage />} index></Route>
            <Route path="applyMentor" element={<ApplyMentorPage />}></Route>
            <Route path="applyDetail/:id" element={<ApplyDetailPage />}></Route>
            <Route path="memberInfo" element={<MemberInfoPage />}></Route>
            <Route path="memberDetail/:id" element={<MemberDetailPage />}></Route>
            <Route path="newRegistration" element={<NewRegistrationPage />}></Route>
            <Route path="platformConfig" element={<PlatformConfigPage />}></Route>
            <Route path="platformNotice" element={<PlatformNoticePage />}></Route>
          </>
        )}
      </Route>

      <Route path="*" element={<NotFound isAdmin={isAdmin} />} index></Route>
    </Routes>
  );
}

export default App;
