import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDecodeToken } from "../../hooks/useDecodeToken";
import { useFetch } from "../../hooks/useFetch";
import toast, { Toaster } from "react-hot-toast";
import styles from "../../styles/ApplyDetail.module.scss";
import MentorSkillList from "./MentorSkillList";
import MentorWorkExp from "./MentorWorkExp";
import MentorEduExp from "./MentorEduExp";
import MentorSchedule from "./MentorSchedule";
import MentorServiceList from "./MentorServiceList";
import { bankList } from "./BankList";
import { IoReturnUpBack } from "react-icons/io5";
import { LiaHomeSolid } from "react-icons/lia";
import { AiOutlineEdit } from "react-icons/ai";
import PopUp from "./PopUp";
import MentorTimeTable from "./MentorTimeTable";

function ApplyDetail() {
  const userInfo = useDecodeToken();
  const applicationId = useParams();
  const navigate = useNavigate();

  const [approvedBy, setApprovedBy] = useState("");
  const [applyInfo, setApplyInfo] = useState<any>({});
  const [popUp, setPopUp] = useState(false);
  const [editType, setEditType] = useState("");

  useEffect(() => {
    if (userInfo) {
      const GetapprovedBy = async () => {
        try {
          const res = await useFetch(`${process.env.REACT_APP_STRAPI}/users/${userInfo.id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.jwtToken}`,
            },
          });

          if (!res.error) {
            setApprovedBy(res.username);
          } else {
            toast.error(res.error.message);
          }
        } catch (e) {
          console.error(e);
        }
      };

      const GetApplyMentor = async () => {
        try {
          const res = await useFetch(
            `${process.env.REACT_APP_STRAPI}/mentor-profiles/${applicationId.id}?populate[0]=User_profile.Users_permissions_user&populate[1]=Skills&populate[2]=Mentor_experiences&populate[3]=Mentor_schedules&populate[4]=Mentor_services.Service`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.jwtToken}`,
              },
            }
          );

          if (!res.error) {
            setApplyInfo(res.data.attributes);
          } else {
            toast.error(res.error.message);
          }
        } catch (e) {
          console.error(e);
        }
      };
      GetapprovedBy();
      GetApplyMentor();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId, popUp]);

  const HandlePermission = async (permission: string) => {
    try {
      if (userInfo) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const response = await useFetch(
          `${process.env.REACT_APP_STRAPI}/mentor-profiles/${applicationId.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.jwtToken}`,
            },
            body: JSON.stringify({
              data: { status: permission, approvedBy: approvedBy },
            }),
          }
        );

        if (response.error) {
          toast.error(response.error.message);
          throw new Error(response.error.message);
        }
        toast.success("已更改！");
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderApplyInfo = () => {
    if (applyInfo.createdAt) {
      return (
        <>
          <Toaster position="top-right" reverseOrder={false} />

          <div className={styles.mentorDetail}>
            <div className={styles.header}>
              <div onClick={() => navigate(-1)}>
                <IoReturnUpBack />
              </div>
              <div onClick={() => navigate("/")}>
                <LiaHomeSolid />
              </div>
            </div>

            <div className={styles.total}>
              <div className={styles.left}>
                <div className={styles.block}>
                  <div className={styles.header}>
                    <div className={styles.subTitle}>申請人名稱</div>

                    <em>
                      {applyInfo.User_profile.data.attributes.first_name}{" "}
                      {applyInfo.User_profile.data.attributes.last_name}
                    </em>
                  </div>

                  <div className={styles.header}>
                    <div className={styles.subTitle}>職位 / 頭銜 / 機構</div>
                    <em> {applyInfo.job_title}</em>
                  </div>

                  <hr />

                  {popUp && editType === "skills" ? (
                    <>
                      <PopUp
                        setPopUp={setPopUp}
                        type={editType}
                        applyInfo={applyInfo}
                        targetId={applicationId.id}
                      />
                    </>
                  ) : (
                    <>
                      <MentorSkillList skill={applyInfo.Skills.data} />
                    </>
                  )}

                  {popUp ? (
                    <></>
                  ) : (
                    <>
                      <div
                        className={styles.edit}
                        onClick={() => {
                          setPopUp(true);
                          setEditType("skills");
                        }}
                      >
                        <AiOutlineEdit />
                      </div>
                    </>
                  )}
                </div>

                <div className={styles.block}>
                  <div className={styles.subTitle}>關於 Mentor</div>

                  {popUp && editType === "description" ? (
                    <>
                      <PopUp
                        setPopUp={setPopUp}
                        type={editType}
                        applyInfo={applyInfo}
                        targetId={applicationId.id}
                      />
                    </>
                  ) : (
                    <>{applyInfo.description}</>
                  )}

                  {popUp ? (
                    <></>
                  ) : (
                    <>
                      <div
                        className={styles.edit}
                        onClick={() => {
                          setPopUp(true);
                          setEditType("description");
                        }}
                      >
                        <AiOutlineEdit />
                      </div>
                    </>
                  )}
                </div>

                <div className={styles.block}>
                  <div className={styles.subTitle}>
                    工作經驗
                    {popUp ? (
                      <></>
                    ) : (
                      <>
                        <div
                          onClick={() => {
                            setPopUp(true);
                            setEditType("workExp");
                          }}
                        >
                          <AiOutlineEdit />
                        </div>
                      </>
                    )}
                  </div>

                  {popUp && editType === "workExp" ? (
                    <>
                      <PopUp
                        setPopUp={setPopUp}
                        type={editType}
                        applyInfo={applyInfo}
                        targetId={applicationId.id}
                      />
                    </>
                  ) : (
                    <>
                      <MentorWorkExp workExp={applyInfo.Mentor_experiences.data} popUp={popUp} />
                    </>
                  )}
                </div>

                <div className={styles.block}>
                  <div className={styles.subTitle}>
                    學歷
                    {popUp ? (
                      <></>
                    ) : (
                      <>
                        <div
                          onClick={() => {
                            setPopUp(true);
                            setEditType("eduExp");
                          }}
                        >
                          <AiOutlineEdit />
                        </div>
                      </>
                    )}
                  </div>

                  {popUp && editType === "eduExp" ? (
                    <>
                      <PopUp
                        setPopUp={setPopUp}
                        type={editType}
                        applyInfo={applyInfo}
                        targetId={applicationId.id}
                      />
                    </>
                  ) : (
                    <>
                      <MentorEduExp eduExp={applyInfo.Mentor_experiences.data} popUp={popUp} />
                    </>
                  )}
                </div>
              </div>

              <div className={styles.right}>
                <div className={styles.block}>
                  <div className={styles.subTitle}>
                    服務日程
                    {popUp ? (
                      <></>
                    ) : (
                      <>
                        <div
                          onClick={() => {
                            setPopUp(true);
                            setEditType("timetable");
                          }}
                        >
                          <AiOutlineEdit />
                        </div>
                      </>
                    )}
                  </div>

                  {popUp && editType === "timetable" ? (
                    <>
                      <MentorTimeTable
                        setPopUp={setPopUp}
                        applyInfo={applyInfo}
                        targetId={applicationId.id}
                      />
                    </>
                  ) : (
                    <>
                      <MentorSchedule mentorSchedule={applyInfo.Mentor_schedules.data} />
                    </>
                  )}
                </div>

                <div className={styles.block}>
                  <div className={styles.subTitle}>
                    <>咨詢類別</>

                    {popUp ? (
                      <></>
                    ) : (
                      <>
                        <div
                          className={styles.edit}
                          onClick={() => {
                            setPopUp(true);
                            setEditType("services");
                          }}
                        >
                          <AiOutlineEdit />
                        </div>
                      </>
                    )}
                  </div>

                  {popUp && editType === "services" ? (
                    <>
                      <PopUp
                        setPopUp={setPopUp}
                        type={editType}
                        applyInfo={applyInfo}
                        targetId={applicationId.id}
                      />
                    </>
                  ) : (
                    <>
                      <MentorServiceList service={applyInfo.Mentor_services.data} />
                    </>
                  )}
                </div>

                <div className={styles.block}>
                  <div className={styles.subTitle}>
                    銀行帳戶
                    {/* <div className={styles.edit}>
                      <AiOutlineEdit />
                    </div> */}
                  </div>

                  <div className={styles.mentorTimetable}>
                    <div className={styles.timetable}>
                      <div className={styles.available}>
                        <div className={styles.item}>
                          <div className={styles.weekday}>收款銀行</div>
                          <div className={styles.timeBox}>
                            <div className={styles.bankName}>
                              {bankList
                                .filter((list: any) => list.id === applyInfo.bankAccount_bank)
                                .map((bank: any) => (
                                  <div key={bank.id}>
                                    {bank.id} {bank.name_ch ? bank.name_ch : bank.name_en}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.weekday}>戶口號碼</div>
                          <div className={styles.timeBox}>{applyInfo.bankAccount_number}</div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.weekday}>收款人全名</div>
                          <div className={styles.timeBox}>{applyInfo.bankAccount_name}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.block}>
                  {applyInfo.status === "pending" ? (
                    <div className={styles.subTitle}>批核申請</div>
                  ) : applyInfo.status === "false" ? (
                    <div className={styles.subTitle}>批核申請 (Mentor未提交申請)</div>
                  ) : (
                    <div className={styles.subTitle}>更新狀態</div>
                  )}

                  <div className={styles.permission}>
                    {applyInfo.status === "pending" && (
                      <>
                        <div onClick={() => HandlePermission("on_board")}>批准</div>
                        <div onClick={() => HandlePermission("rejected")}>拒絕</div>
                      </>
                    )}

                    {applyInfo.status === "false" && (
                      <>
                        <div onClick={() => HandlePermission("on_board")}>批准</div>
                        <div onClick={() => HandlePermission("rejected")}>拒絕</div>
                      </>
                    )}

                    {applyInfo.status === "on_board" && (
                      <>
                        <div onClick={() => HandlePermission("pending")}>要求更新</div>
                        <div onClick={() => HandlePermission("rejected")}>暫時隱藏</div>
                      </>
                    )}

                    {applyInfo.status === "rejected" && (
                      <>
                        <div onClick={() => HandlePermission("pending")}>要求更新</div>
                        <div onClick={() => HandlePermission("on_board")}>重新顯示</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return <>{renderApplyInfo()}</>;
}

export default ApplyDetail;
