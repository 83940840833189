import React from "react";
import styles from "../../styles/MemberInfo.module.scss";
import { useNavigate } from "react-router-dom";

function PlatformConfig() {
  const navigate = useNavigate();

  return (
    <div className={styles.memberCard} onClick={() => navigate(`/platformNotice`)}>
      Platform Notice
    </div>
  );
}

export default PlatformConfig;
