import React from "react";
import ApplyDetail from "../../components/applyMentor/ApplyDetail";

function ApplyDetailPage() {
  return (
    <div>
      <ApplyDetail />
    </div>
  );
}

export default ApplyDetailPage;
