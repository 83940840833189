import React from "react";

import styles from "../../styles/ApplyDetail.module.scss";

const weekdays = [
  { name: "星期一", value: "Mon" },
  { name: "星期二", value: "Tue" },
  { name: "星期三", value: "Wed" },
  { name: "星期四", value: "Thu" },
  { name: "星期五", value: "Fri" },
  { name: "星期六", value: "Sat" },
  { name: "星期日", value: "Sun" },
];

function MentorSchedule({ mentorSchedule }: any) {
  return (
    <div className={styles.mentorTimetable}>
      <div className={styles.timetable}>
        <div className={styles.available}>
          {weekdays.map((weekday: any, idx: any) => {
            const currentWeekday = mentorSchedule
              .map((schedule: any) => schedule.attributes.week)
              .includes(weekday.value);
            const getCurrentWeekday = mentorSchedule.filter(
              (schedule: any) => schedule.attributes.week === weekday.value
            );

            return (
              <div className={styles.item} key={idx}>
                <div className={styles.weekday}>{weekday.name}</div>
                <div className={styles.timeBox}>
                  {currentWeekday ? (
                    <>
                      {getCurrentWeekday
                        .sort(({ available_start: a }: any, { available_start: b }: any) =>
                          a > b ? 1 : a < b ? -1 : 0
                        )
                        .map((y: any, idx: any) => (
                          <div className={styles.timeBoxItem} key={idx}>
                            <header>
                              <div className={styles.timeInput}>
                                {y.attributes.available_start.slice(0, 5)}
                              </div>
                              -
                              <div className={styles.timeInput}>
                                {y.attributes.available_end.slice(0, 5)}
                              </div>
                            </header>
                          </div>
                        ))}
                    </>
                  ) : (
                    <div className={styles.des}>不提供服務</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MentorSchedule;
