import NewRegistration from "../../components/newRegistration/NewRegistration";

import styles from "../../styles/MemberInfo.module.scss";
import { useNavigate } from "react-router";
import { IoReturnUpBack } from "react-icons/io5";
import { LiaHomeSolid } from "react-icons/lia";

function NewRegistrationPage() {
  const navigate = useNavigate();

  return (
    <div className={styles.memberInfo}>
      <div className={styles.header}>
        <div onClick={() => navigate(-1)}>
          <IoReturnUpBack />
        </div>
        <div onClick={() => navigate("/")}>
          <LiaHomeSolid />
        </div>
      </div>

      <div className={styles.newRegSubTitle}>
        <span>用戶ID</span>
        <span>註冊日期</span>
        <span>用戶名稱</span>
      </div>

      <div className={styles.memberList}>
        <NewRegistration />
      </div>
    </div>
  );
}

export default NewRegistrationPage;
