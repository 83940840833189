import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

import { useFetch } from "../../hooks/useFetch";

interface AuthState {
  isAuth: boolean;
  email: string;
  loading: boolean;
  error: string | undefined;
}

interface JWTPayload {
  access_token: string;
}

interface LoginResponse {
  jwt: string;
  user: {
    isAdmin: boolean;
  };
}

let initialState: AuthState;
initialState = {
  isAuth: !!window.localStorage.getItem("token"),
  email: "",
  loading: false,
  error: undefined,
};

export const loginThunk = createAsyncThunk<
  LoginResponse,
  { email: string; password: string },
  { rejectValue: string }
>("@auth/login", async ({ email, password }, thunkAPI) => {
  try {
    const res = await useFetch(`${process.env.REACT_APP_STRAPI}/auth/local`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: email,
        password: password,
      }),
    });

    if (res.error) {
      throw new Error(res.error.message);
    }

    return res;
  } catch (error) {
    return thunkAPI.rejectWithValue("AUTH Login failed");
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.loading = false;
        let decoded: JWTPayload = jwt_decode(action.payload.jwt);
        state.email = decoded.access_token;
        state.isAuth = true;
        localStorage.setItem("token", action.payload.jwt);
        localStorage.setItem("isAdmin", action.payload.user.isAdmin.toString());
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default authSlice.reducer;
