import React from "react";
import styles from "../../styles/ApplyDetail.module.scss";

function MentorSkillList({ skill }: any) {
  const renderSkillList = () => {
    return skill.map((list: any, index: number) => {
      return (
        <div className={styles.mentorSkillCard} key={list.attributes.createdAt}>
          <header>
            <span>{index + 1}</span>
            <p>{list.attributes.name}</p>
          </header>
          <span>
            簡介 : <em>{list.attributes.description}</em>
          </span>
        </div>
      );
    });
  };

  return (
    <>
      <div className={styles.subTitle}>
        <span>擅長技能</span>
      </div>

      <div className={styles.mentorSkillList}>{renderSkillList()}</div>
    </>
  );
}

export default MentorSkillList;
