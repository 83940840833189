import React from "react";
import { MdDeleteForever } from "react-icons/md";

import styles from "../../styles/ApplyDetail.module.scss";
import { fetcher } from "../../lib/fetcher";
import { useDecodeToken } from "../../hooks/useDecodeToken";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function MentorServiceList({ service }: any) {
  const userInfo = useDecodeToken();
  const navigate = useNavigate();

  const deleteService = async (serviceId: number) => {
    if (userInfo) {
      try {
        const res = await fetcher(`${process.env.REACT_APP_STRAPI}/mentor-services/${serviceId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo.jwtToken}`,
          },
          body: JSON.stringify({
            data: {
              is_active: false,
              is_delete: true,
            },
          }),
        });

        if (res.error) {
          toast.error(res.error.message);
          throw new Error(res.error.message);
        }

        toast.success("已刪除！");
        navigate(-1);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderServiceList = () => {
    return service
      .filter(
        (list: any) => list.attributes.is_active === true && list.attributes.is_delete === false
      )
      .map((service: any) => (
        <div key={service.attributes.createdAt} className={styles.serviceCardList}>
          <div className={styles.serviceCard}>
            <div className={styles.detail}>
              <header>
                <div>
                  <span>{service.attributes.Service.data.attributes.name}</span>
                  <span className={styles.price}>
                    <span>HK${service.attributes.price}</span>
                  </span>
                </div>
                <small>{service.attributes.duration} 分鐘</small>
              </header>
              <small>{service.attributes.Service.data.attributes.description}</small>
              <div className={styles.icon}>
                <div onClick={() => deleteService(service.id)}>
                  <MdDeleteForever />
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
  };
  return <>{renderServiceList()}</>;
}

export default MentorServiceList;
