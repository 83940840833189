import React, { useEffect, useState } from "react";
import { useDecodeToken } from "../../hooks/useDecodeToken";
import { useNavigate } from "react-router-dom";
import { fetcher } from "../../lib/fetcher";
import toast from "react-hot-toast";
import { TbPencilCancel } from "react-icons/tb";
import { LuUpload } from "react-icons/lu";
import styles from "../../styles/MentorTimetable.module.scss";

function MentorTimeTable({ setPopUp, applyInfo, targetId }: any) {
  const userInfo = useDecodeToken();
  const navigate = useNavigate();

  const weekdays = [
    { name: "星期一", value: "Mon" },
    { name: "星期二", value: "Tue" },
    { name: "星期三", value: "Wed" },
    { name: "星期四", value: "Thu" },
    { name: "星期五", value: "Fri" },
    { name: "星期六", value: "Sat" },
    { name: "星期日", value: "Sun" },
  ];

  const startHour = 8;
  const endHour = 22;
  const defaultTimeInterval = 60;
  const times: any = [];

  for (let i = startHour; i < endHour; i++) {
    for (let y = 0; y < 60; y += defaultTimeInterval) {
      times.push(`${i < 10 ? `0${i}` : i}:${y === 0 ? "00" : y}`);
    }
  }
  times.push(`${endHour}:00`);

  const [defaultTime, setDefaultTime] = useState({
    start: applyInfo.default_schedule_start || "00:00",
    end: applyInfo.default_schedule_end || "00:01",
  });

  const [available, setAvailable] = useState<any>(
    applyInfo.Mentor_schedules.data.filter((item: any) => item.type === "available")
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [overwrite, setOverwrite] = useState<any>(
    applyInfo.Mentor_schedules.data.filter((item: any) => item.type === "overwrite")
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [disable, setDisable] = useState<boolean>(false);

  const handleDefaultTimeChange = (e: any, type: string) => {
    const value = e.target.value;
    switch (type) {
      case "start":
        setDefaultTime({ ...defaultTime, start: value });
        break;
      case "end":
        setDefaultTime({ ...defaultTime, end: value });
        break;
      default:
        break;
    }
  };

  const handleWeekdaysChange = (e: any) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setAvailable(
      isChecked
        ? [
            ...available,
            {
              type: "available",
              week: value,
              available_start: defaultTime.start,
              available_end: defaultTime.end,
            },
          ]
        : available.filter((item: any) => item.week !== value)
    );
  };

  const handleTimeChange = (e: any, type: string, idx: number) => {
    const newFields = [...available];
    const value = e.target.value;

    switch (type) {
      case "start":
        available[idx].available_start = value;
        setAvailable(newFields);
        break;
      case "end":
        available[idx].available_end = value;
        setAvailable(newFields);
        break;
      default:
        break;
    }
  };

  const addTimeFields = (value: any) => {
    setAvailable([
      ...available,
      {
        type: "available",
        week: value,
        available_start: defaultTime.start,
        available_end: defaultTime.end,
      },
    ]);
  };

  const removeTimeFields = (i: any) => {
    const newFields = [...available];
    newFields.splice(i, 1);
    setAvailable(newFields);
  };

  const overlapping = (a: any, b: any) => {
    const getMinutes = (s: any) => {
      const p = s.split(":").map(Number);
      return p[0] * 60 + p[1];
    };
    return (
      getMinutes(a.available_end) > getMinutes(b.available_start) &&
      getMinutes(b.available_end) > getMinutes(a.available_start)
    );
  };

  const isOverlapping = (arr: any) => {
    let i, j;
    for (i = 0; i < arr.length - 1; i++) {
      for (j = i + 1; j < arr.length; j++) {
        if (overlapping(arr[i], arr[j])) {
          return true;
        }
      }
    }
    return false;
  };

  const checkError = () => {
    let disable = false;

    // eslint-disable-next-line array-callback-return
    weekdays.map((x: any) => {
      const getCurrentWeekday = available.filter((item: any) => item.week === x.value);
      if (isOverlapping(getCurrentWeekday)) {
        disable = true;
      }

      // eslint-disable-next-line array-callback-return
      getCurrentWeekday.map((y: any) => {
        if (!y.available_start || !y.available_end) {
          disable = true;
        } else if (y.available_start === "00:00" || y.available_end === "00:01") {
          disable = true;
        } else if (y.available_end <= y.available_start) {
          disable = true;
        }
      });
    });

    return disable;
  };

  useEffect(() => {
    setDisable(checkError());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [available]);

  const handleSubmit = async (e: any) => {
    if (userInfo) {
      if (
        applyInfo.default_schedule_start !== defaultTime.start ||
        applyInfo.default_schedule_end !== defaultTime.end
      ) {
        try {
          await fetcher(`${process.env.REACT_APP_STRAPI}/mentor-profiles/${targetId}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.jwtToken}`,
            },
            body: JSON.stringify({
              data: {
                default_schedule_start: defaultTime.start === "00:00" ? null : defaultTime.start,
                default_schedule_end: defaultTime.end === "00:01" ? null : defaultTime.end,
              },
            }),
          });
        } catch (error) {
          console.error("error with request", error);
        }
      }

      try {
        const mentorTimetable = applyInfo.Mentor_schedules.data;

        // delete all mentor schedules
        await mentorTimetable.forEach(async (x: any) => {
          await fetcher(`${process.env.REACT_APP_STRAPI}/mentor-schedules/${x.id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.jwtToken}`,
            },
          });
        });

        // create all mentor schedules

        available.length > 0 &&
          (await available.forEach(async (x: any) => {
            await fetcher(`${process.env.REACT_APP_STRAPI}/mentor-schedules/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.jwtToken}`,
              },
              body: JSON.stringify({
                data: {
                  Mentor: targetId,
                  type: x.type,
                  week: x.week,
                  available_start: x.available_start,
                  available_end: x.available_end,
                },
              }),
            });
          }));

        overwrite.length > 0 &&
          (await overwrite.forEach(async (y: any) => {
            await fetcher(`${process.env.REACT_APP_STRAPI}/mentor-schedules/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.jwtToken}`,
              },
              body: JSON.stringify({
                data: {
                  Mentor: targetId,
                  type: y.type,
                  overwrite_date: y.overwrite_date,
                  overwrite_start: null,
                  overwrite_end: null,
                },
              }),
            });
          }));
        toast.success("已儲存！");
        navigate(-1);
      } catch (error) {
        console.error("error with request", error);
      }
    }
  };

  return (
    <form className={styles.MentorTimetable}>
      <div className={styles.top}>
        <div className={styles.side}>
          <div className={styles.box}>
            <span className={styles.subTitle}>預設的開始與結束時間</span>
            <div className={styles.timeBoxItem}>
              <header>
                <div className={styles.timeInput}>
                  <select
                    value={defaultTime.start}
                    onChange={(e: any) => handleDefaultTimeChange(e, "start")}
                  >
                    <option value="00:00">選擇開始時間</option>
                    {times.map((x: any, idx: any) => (
                      <option value={`${x}:00`} key={idx}>
                        {x}
                      </option>
                    ))}
                  </select>
                </div>
                -
                <div className={styles.timeInput}>
                  <select
                    value={defaultTime.end}
                    onChange={(e: any) => handleDefaultTimeChange(e, "end")}
                  >
                    <option value="00:01">選擇結束時間</option>
                    {times.map((x: any, idx: any) => (
                      <option value={`${x}:00`} key={idx}>
                        {x}
                      </option>
                    ))}
                  </select>
                </div>
              </header>
              {defaultTime.start && defaultTime.end && defaultTime.end <= defaultTime.start && (
                <div className="error_msg">*開始時間需早於結束時間</div>
              )}
            </div>
          </div>

          <div className={styles.box}>
            <div className={styles.bottom}>
              <div onClick={() => setPopUp(false)}>
                <TbPencilCancel />
              </div>

              <div className="button" onClick={(e: any) => handleSubmit(e)}>
                <LuUpload />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.main}>
          <span className={styles.subTitle}>設定您每週能提供服務的例行日程</span>
          <div className={styles.list}>
            {weekdays.map((weekday: any, idx: any) => {
              const currentWeekday = available.map((x: any) => x.week).includes(weekday.value);

              const getCurrentWeekday = available.filter(
                (item: any) => item.week === weekday.value
              );

              return (
                <div className={styles.item} key={idx}>
                  <div className={styles.weekday}>
                    <input
                      type="checkbox"
                      id={weekday.value}
                      name={weekday.value}
                      value={weekday.value}
                      onChange={handleWeekdaysChange}
                      checked={currentWeekday}
                    />
                    <label htmlFor={weekday.value}>{weekday.name}</label>
                  </div>
                  <div className={styles.timeBox}>
                    {currentWeekday ? (
                      <>
                        {getCurrentWeekday
                          .sort(({ available_start: a }: any, { available_start: b }: any) =>
                            a > b ? 1 : a < b ? -1 : 0
                          )
                          .map((y: any, idx: any) => (
                            <div className={styles.timeBoxItem} key={idx}>
                              <header>
                                <div className={styles.timeInput}>
                                  <select
                                    value={y.available_start}
                                    onChange={(e: any) =>
                                      handleTimeChange(e, "start", available.indexOf(y))
                                    }
                                  >
                                    <option value="">選擇開始時間</option>
                                    {times.map((x: any, idx: any) => (
                                      <option value={`${x}:00`} key={idx}>
                                        {x}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                -
                                <div className={styles.timeInput}>
                                  <select
                                    value={y.available_end}
                                    onChange={(e: any) =>
                                      handleTimeChange(e, "end", available.indexOf(y))
                                    }
                                  >
                                    <option value="">選擇結束時間</option>
                                    {times.map((x: any, idx: any) => (
                                      <option value={`${x}:00`} key={idx}>
                                        {x}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {available.filter((item: any) => item.week === weekday.value)
                                  .length > 1 ? (
                                  <>
                                    <i
                                      className="bx bx-trash"
                                      onClick={() => removeTimeFields(available.indexOf(y))}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <label htmlFor={weekday.value}>
                                      <i className="bx bx-trash" />
                                    </label>
                                  </>
                                )}
                              </header>
                              {y.available_end &&
                                y.available_start &&
                                y.available_end <= y.available_start && (
                                  <div className="error_msg">*開始時間需早於結束時間</div>
                                )}
                            </div>
                          ))}
                        {isOverlapping(getCurrentWeekday) && (
                          <div className="error_msg">*此日程有重複時段</div>
                        )}
                      </>
                    ) : (
                      <div className={styles.des}>不提供服務</div>
                    )}
                  </div>
                  {currentWeekday ? (
                    <>
                      <div className={styles.addBtn} onClick={() => addTimeFields(weekday.value)}>
                        <i className="bx bx-add-to-queue" />
                      </div>
                    </>
                  ) : (
                    <>
                      <label htmlFor={weekday.value}>
                        <div className={styles.addBtn}>
                          <i className="bx bx-add-to-queue" />
                        </div>
                      </label>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </form>
  );
}

export default MentorTimeTable;
