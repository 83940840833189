import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDecodeToken } from "../../hooks/useDecodeToken";
import { useFetch } from "../../hooks/useFetch";
import toast, { Toaster } from "react-hot-toast";
import styles from "../../styles/MemberInfo.module.scss";

function MemberInfo() {
  const userInfo = useDecodeToken();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [memberNumber, setMemberNumber] = useState(0);
  const [memberList, setMemberList] = useState([]);
  const [filterMemberList, setFilterMemberList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [goalList, setGoalList] = useState([]);

  const [dateFilterType, setDateFilterType] = useState("");
  const [topicFilterType, setTopicFilterType] = useState("");
  const [goalFilterType, setGoalFilterType] = useState("");
  const [jobFilterType, setJobFilterType] = useState<any>("");
  const [yearsOldFilterType, setYearsOldFilterType] = useState("");

  useEffect(() => {
    const limit = 4;
    const start = (pageNumber - 1) * limit;

    if (userInfo) {
      const GetMember = async () => {
        try {
          const res = await useFetch(`${process.env.REACT_APP_STRAPI}/users`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.jwtToken}`,
            },
          });

          if (!res.error) {
            setMemberNumber(res.length);
          } else {
            toast.error(res.error.message);
          }
        } catch (e) {
          console.error(e);
        }
      };
      const GetApplyMentor = async () => {
        try {
          const res = await useFetch(
            `${process.env.REACT_APP_STRAPI}/users?start=${start}&limit=${limit}&sort[0]=id:asc&populate[0]=User_profile`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.jwtToken}`,
              },
            }
          );

          if (!res.error) {
            setMemberList(res);
          } else {
            toast.error(res.error.message);
          }
        } catch (e) {
          console.error(e);
        }
      };

      const GetTopic = async () => {
        try {
          const res = await useFetch(`${process.env.REACT_APP_STRAPI}/user-topics?fields[0]=name`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.jwtToken}`,
            },
          });

          if (!res.error) {
            setTopicList(res.data);
          } else {
            toast.error(res.error.message);
          }
        } catch (e) {
          console.error(e);
        }
      };

      const GetGoal = async () => {
        try {
          const res = await useFetch(`${process.env.REACT_APP_STRAPI}/user-goals?fields[0]=name`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.jwtToken}`,
            },
          });

          if (!res.error) {
            setGoalList(res.data);
          } else {
            toast.error(res.error.message);
          }
        } catch (e) {
          console.error(e);
        }
      };

      GetMember();
      GetApplyMentor();
      GetTopic();
      GetGoal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    setTopicFilterType("select");
    setGoalFilterType("select");
    setJobFilterType("select");
    setYearsOldFilterType("select");

    if (userInfo) {
      if (dateFilterType === "last7") {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        const last7 = date.toISOString();

        const GetApplyMentor = async () => {
          try {
            const res = await useFetch(
              `${process.env.REACT_APP_STRAPI}/users?sort[0]=createdAt:desc&populate[0]=User_profile&filters[createdAt][$gte]=${last7}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userInfo.jwtToken}`,
                },
              }
            );

            if (res.length < 1) {
              toast.error("沒有適配條件的會員");
            } else if (!res.error) {
              setFilterMemberList(res);
            } else {
              toast.error(res.error.message);
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetApplyMentor();
      } else if (dateFilterType === "last14") {
        const date = new Date();
        date.setDate(date.getDate() - 14);
        const last14 = date.toISOString();

        const GetApplyMentor = async () => {
          try {
            const res = await useFetch(
              `${process.env.REACT_APP_STRAPI}/users?sort[0]=createdAt:desc&populate[0]=User_profile&filters[createdAt][$gte]=${last14}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userInfo.jwtToken}`,
                },
              }
            );

            if (!res.error) {
              setFilterMemberList(res);
            } else {
              toast.error(res.error.message);
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetApplyMentor();
      } else if (dateFilterType === "last30") {
        const date = new Date();
        date.setDate(date.getDate() - 30);
        const last30 = date.toISOString();

        const GetApplyMentor = async () => {
          try {
            const res = await useFetch(
              `${process.env.REACT_APP_STRAPI}/users?sort[0]=createdAt:desc&populate[0]=User_profile&filters[createdAt][$gte]=${last30}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userInfo.jwtToken}`,
                },
              }
            );

            if (!res.error) {
              setFilterMemberList(res);
            } else {
              toast.error(res.error.message);
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetApplyMentor();
      } else if (dateFilterType === "last60") {
        const date = new Date();
        date.setDate(date.getDate() - 60);
        const last60 = date.toISOString();

        const GetApplyMentor = async () => {
          try {
            const res = await useFetch(
              `${process.env.REACT_APP_STRAPI}/users?sort[0]=createdAt:desc&populate[0]=User_profile&filters[createdAt][$gte]=${last60}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userInfo.jwtToken}`,
                },
              }
            );

            if (!res.error) {
              setFilterMemberList(res);
            } else {
              toast.error(res.error.message);
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetApplyMentor();
      } else if (dateFilterType === "null") {
        setFilterMemberList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilterType]);

  useEffect(() => {
    setDateFilterType("");
    setGoalFilterType("select");
    setJobFilterType("select");
    setYearsOldFilterType("select");

    if (userInfo) {
      if (topicFilterType !== "" && topicFilterType !== "select") {
        const GetApplyMentor = async () => {
          try {
            const res = await useFetch(
              `${process.env.REACT_APP_STRAPI}/users?populate[0]=User_profile.User_topics`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userInfo.jwtToken}`,
                },
              }
            );

            if (!res.error) {
              const filter = res
                .flatMap((data: any) =>
                  data.User_profile.User_topics.map((topic: any) => {
                    if (topic.name === topicFilterType) {
                      return data;
                    }
                    return undefined;
                  })
                )
                .filter((data: any) => data !== undefined)
                .sort((a: any, b: any) => a.id - b.id);

              if (filter.length > 0) {
                setFilterMemberList(filter);
              } else {
                toast.error("沒有適配條件的會員");
              }
            } else {
              toast.error(res.error.message);
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetApplyMentor();
      } else if (topicFilterType === "select") {
        setFilterMemberList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicFilterType]);

  useEffect(() => {
    setDateFilterType("");
    setTopicFilterType("select");
    setJobFilterType("select");
    setYearsOldFilterType("select");

    if (userInfo) {
      if (goalFilterType !== "" && goalFilterType !== "select") {
        const GetApplyMentor = async () => {
          try {
            const res = await useFetch(
              `${process.env.REACT_APP_STRAPI}/users?populate[0]=User_profile.User_goals`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userInfo.jwtToken}`,
                },
              }
            );

            if (!res.error) {
              const filter = res
                .flatMap((data: any) =>
                  data.User_profile.User_goals.map((goal: any) => {
                    if (goal.name === goalFilterType) {
                      return data;
                    }
                    return undefined;
                  })
                )
                .filter((data: any) => data !== undefined)
                .sort((a: any, b: any) => a.id - b.id);

              if (filter.length > 0) {
                setFilterMemberList(filter);
              } else {
                toast.error("沒有適配條件的會員");
              }
            } else {
              toast.error(res.error.message);
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetApplyMentor();
      } else if (topicFilterType === "select") {
        setFilterMemberList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalFilterType]);

  useEffect(() => {
    setDateFilterType("");
    setTopicFilterType("select");
    setGoalFilterType("select");
    setYearsOldFilterType("select");

    if (userInfo) {
      if (jobFilterType === "select") {
        setFilterMemberList([]);
      } else if (jobFilterType !== "曾就業" && jobFilterType !== "") {
        const GetApplyMentor = async () => {
          try {
            const res = await useFetch(
              `${process.env.REACT_APP_STRAPI}/users?populate[0]=User_profile.Profession`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userInfo.jwtToken}`,
                },
              }
            );

            if (!res.error) {
              const filter = res.filter(
                (data: any) => data.User_profile.Profession.name === jobFilterType
              );
              if (filter.length > 0) {
                setFilterMemberList(filter);
              } else {
                toast.error("沒有適配條件的會員");
              }
            } else {
              toast.error(res.error.message);
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetApplyMentor();
      } else if (jobFilterType === "曾就業") {
        const GetApplyMentor = async () => {
          try {
            const res = await useFetch(
              `${process.env.REACT_APP_STRAPI}/users?populate[0]=User_profile.Profession`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userInfo.jwtToken}`,
                },
              }
            );

            if (!res.error) {
              const filter = res
                .filter((data: any) => data.User_profile.Profession.name !== "未曾就業")
                .sort((a: any, b: any) => a.id - b.id);
              setFilterMemberList(filter);
            } else {
              toast.error(res.error.message);
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetApplyMentor();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobFilterType]);

  useEffect(() => {
    setDateFilterType("");
    setTopicFilterType("select");
    setGoalFilterType("select");
    setJobFilterType("select");

    if (userInfo) {
      if (yearsOldFilterType !== "" && yearsOldFilterType !== "select") {
        const GetApplyMentor = async () => {
          try {
            const res = await useFetch(
              `${process.env.REACT_APP_STRAPI}/users?populate[0]=User_profile`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userInfo.jwtToken}`,
                },
              }
            );

            if (!res.error) {
              const filter = res
                .filter((data: any) => data.User_profile.birthday === yearsOldFilterType)
                .sort((a: any, b: any) => a.id - b.id);

              if (filter.length > 0) {
                setFilterMemberList(filter);
              } else {
                toast.error("沒有適配條件的會員");
              }
            } else {
              toast.error(res.error.message);
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetApplyMentor();
      } else if (topicFilterType === "select") {
        setFilterMemberList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearsOldFilterType]);

  const renderMemberList = () => {
    if (filterMemberList.length < 1) {
      return memberList.map((list: any) => {
        return (
          <div
            className={styles.memberCard}
            key={list.id}
            onClick={() => navigate(`/memberDetail/${list.id}`)}
          >
            <p>{list.id}</p>
            {list.User_profile.first_name} {list.User_profile.last_name}
          </div>
        );
      });
    } else {
      return filterMemberList.map((list: any) => {
        return (
          <div
            className={styles.memberCard}
            key={list.id}
            onClick={() => navigate(`/memberDetail/${list.id}`)}
          >
            <p>{list.id}</p>
            {list.User_profile.first_name} {list.User_profile.last_name}
          </div>
        );
      });
    }
  };

  const handleFilterChange = (e: any, type: string) => {
    const name = e.target.name;
    if (type === "date") {
      setDateFilterType((prev) => (prev === name ? "null" : name));
    }
    window.scrollTo({
      top: window.scrollY + window.innerHeight * 0.5,
      behavior: "smooth",
    });
  };

  const filter = () => {
    return (
      <>
        <div className={styles.applyDate}>
          <div className={styles.filterType}>註冊期</div>
          <div className={styles.filterBox}>
            <label>
              <input
                type="checkbox"
                id="last7"
                name="last7"
                checked={dateFilterType === "last7"}
                onChange={(e) => {
                  handleFilterChange(e, "date");
                }}
              />
              最近7日
            </label>
            <label>
              <input
                type="checkbox"
                id="last14"
                name="last14"
                checked={dateFilterType === "last14"}
                onChange={(e) => {
                  handleFilterChange(e, "date");
                }}
              />
              最近14日
            </label>
            <label>
              <input
                type="checkbox"
                id="last30"
                name="last30"
                checked={dateFilterType === "last30"}
                onChange={(e) => {
                  handleFilterChange(e, "date");
                }}
              />
              最近30日
            </label>
            <label>
              <input
                type="checkbox"
                id="last60"
                name="last60"
                checked={dateFilterType === "last60"}
                onChange={(e) => {
                  handleFilterChange(e, "date");
                }}
              />
              最近60日
            </label>
          </div>
        </div>

        <div className={styles.applyDate}>
          <div className={styles.filterType}>有興趣的主題</div>
          <div className={styles.filterBox}>
            <select
              name="topic"
              id="topic"
              onChange={(event) => {
                setTopicFilterType(event.target.value);
              }}
            >
              <option value="select">所有</option>

              {topicList &&
                (dateFilterType === "" || dateFilterType === "null") &&
                (goalFilterType === "" || goalFilterType === "select") &&
                (jobFilterType === "" || jobFilterType === "select") &&
                (yearsOldFilterType === "" || yearsOldFilterType === "select") &&
                topicList.map((topic: any) => (
                  <option key={topic.attributes.name} value={topic.attributes.name}>
                    {topic.attributes.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className={styles.applyDate}>
          <div className={styles.filterType}>學習目標</div>
          <div className={styles.filterBox}>
            <select
              name="goal"
              id="goal"
              onChange={(event) => setGoalFilterType(event.target.value)}
            >
              <option value="select">所有</option>
              {goalList &&
                (dateFilterType === "" || dateFilterType === "null") &&
                (topicFilterType === "" || topicFilterType === "select") &&
                (jobFilterType === "" || jobFilterType === "select") &&
                (yearsOldFilterType === "" || yearsOldFilterType === "select") &&
                goalList.map((goal: any) => (
                  <option key={goal.attributes.name} value={goal.attributes.name}>
                    {goal.attributes.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className={styles.applyDate}>
          <div className={styles.filterType}>就業情況</div>
          <div className={styles.filterBox}>
            <select
              name="job"
              id="job"
              onChange={(event) => {
                setJobFilterType(event.target.value);
              }}
            >
              <option value="select">所有</option>
              {(dateFilterType === "" || dateFilterType === "null") &&
                (topicFilterType === "" || topicFilterType === "select") &&
                (goalFilterType === "" || goalFilterType === "select") &&
                (yearsOldFilterType === "" || yearsOldFilterType === "select") && (
                  <>
                    <option value="曾就業">有就業經驗</option>
                    <option value="現正就讀大學">現正就讀大學</option>
                    <option value="現正就讀中學">現正就讀中學</option>
                    <option value="現正就讀小學">現正就讀小學</option>
                  </>
                )}
            </select>
          </div>
        </div>

        <div className={styles.applyDate}>
          <div className={styles.filterType}>年齡層</div>
          <div className={styles.filterBox}>
            <select
              name="yearsOld"
              id="yearsOld"
              onChange={(event) => {
                setYearsOldFilterType(event.target.value);
              }}
            >
              <option value="select">所有</option>
              {(dateFilterType === "" || dateFilterType === "null") &&
                (topicFilterType === "" || topicFilterType === "select") &&
                (goalFilterType === "" || goalFilterType === "select") &&
                (jobFilterType === "" || jobFilterType === "select") && (
                  <>
                    <option value="from15to17">15-17</option>
                    <option value="from18to22">18-22</option>
                    <option value="from23to28">23-28</option>
                    <option value="from29to35">29-35</option>
                    <option value="from35toabove">35以上</option>
                  </>
                )}
            </select>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className={styles.filter}>{filter()}</div>

      <div className={styles.subTitle}>
        <span>用戶ID</span>
        <span>用戶名稱</span>
      </div>
      {renderMemberList()}

      {filterMemberList.length < 1 && (
        <div className={styles.footer}>
          <div onClick={() => setPageNumber(pageNumber - 1)}>
            {pageNumber === 1 ? (
              <span style={{ color: "white" }}>上一批</span>
            ) : (
              <span>上一批</span>
            )}
          </div>

          <div>第 {pageNumber} 頁</div>

          <div onClick={() => setPageNumber(pageNumber + 1)}>
            {memberList.length % 4 === 0 && memberList.length * pageNumber < memberNumber ? (
              <span>下一批</span>
            ) : (
              <span style={{ color: "white" }}>下一批</span>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default MemberInfo;
