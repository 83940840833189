import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import styles from "../../styles/PopUp.module.scss";

function WorkExpEdit({ setMentorSelection, type, isActive, setIsActive }: any) {
  const applicationId = useParams();

  const [workExp, setWorkExp] = useState({
    Mentor: applicationId.id,
    type: type,
    title_name: "",
    org_name: "",
    start_year: 0,
    start_month: 0,
    end_year: 0,
    end_month: 0,
  });

  const startYear = 1940;
  const endYear = new Date().getFullYear();
  const years: any = [];

  for (let i = endYear; i >= startYear; i--) {
    years.push(i);
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    if (workExp.end_year > 0 && workExp.end_year < workExp.start_year) {
      toast.error("結束年份不能早於開始年份");
    }

    setMentorSelection(workExp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workExp]);

  useEffect(() => {
    if (isActive) {
      setWorkExp((prevState) => ({
        ...prevState,
        end_year: 0,
        end_month: 0,
      }));
    }
  }, [isActive]);

  const handleOnChange = (e: any, type: string) => {
    const fieldMap = {
      start_year: "start_year",
      start_month: "start_month",
      end_year: "end_year",
      end_month: "end_month",
    };
    const field = fieldMap[type as keyof typeof fieldMap];

    const newDate = parseInt(e.target.value);

    if (!isActive) {
      setWorkExp((prevState) => ({
        ...prevState,
        [field]: isNaN(newDate) ? 0 : newDate,
      }));
    }
  };

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />

      <div className={styles.experience}>
        <div>
          {type === "workExp" ? <>工作職銜*</> : <>主修科目*</>}
          <input
            id="description"
            type="text"
            onChange={(e) =>
              setWorkExp((prevState) => ({
                ...prevState,
                title_name: e.target.value,
              }))
            }
          />
        </div>

        <div>
          {type === "workExp" ? <>公司名稱*</> : <>教育機構*</>}
          <input
            id="description"
            type="text"
            onChange={(e) =>
              setWorkExp((prevState) => ({
                ...prevState,
                org_name: e.target.value,
              }))
            }
          />
        </div>

        <div className={styles.select}>
          <div className={styles.selectTitle}>開始日期*</div>
          <div className={styles.selectDate}>
            <select id="start_year" onChange={(e) => handleOnChange(e, "start_year")}>
              <option value="">開始年份</option>
              {years.map((item: any, index: number) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
            <select id="start_month" onChange={(e) => handleOnChange(e, "start_month")}>
              <option value="">開始月份</option>
              {months.map((item: any, index: any) => (
                <option value={index + 1} key={index}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className={styles.select}>
          <div className={styles.selectTitle}>結束日期*</div>
          <div className={styles.selectDate}>
            <select id="end_year" onChange={(e) => handleOnChange(e, "end_year")}>
              <option value="">結束日期</option>
              {!isActive &&
                years.map((item: any, index: number) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
            </select>
            <select id="start_month" onChange={(e) => handleOnChange(e, "end_month")}>
              <option value="">結束月份</option>
              {!isActive &&
                months.map((item: any, index: any) => (
                  <option value={index + 1} key={index}>
                    {item}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {type === "workExp" ? (
          <div className={isActive ? styles.active : styles.button}>
            <button onClick={(e) => setIsActive(!isActive)}>
              <span>我目前在這裡工作</span>
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default WorkExpEdit;
