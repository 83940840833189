import React, { useEffect, useState } from "react";
import { useDecodeToken } from "../../hooks/useDecodeToken";
import { useFetch } from "../../hooks/useFetch";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/MemberInfo.module.scss";
import moment from "moment";

function NewRegistration() {
  const userInfo = useDecodeToken();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [memberNumber, setMemberNumber] = useState(0);
  const [memberList, setMemberList] = useState([]);
  const [newMemberList, setNewMemberList] = useState([]);

  useEffect(() => {
    const limit = 5;
    const start = (pageNumber - 1) * limit;

    const date = new Date();
    date.setDate(date.getDate() - 7);
    const last7 = date.toISOString();

    if (userInfo) {
      const GetMember = async () => {
        try {
          const res = await useFetch(
            `${process.env.REACT_APP_STRAPI}/users?populate[0]=User_profile&filters[createdAt][$gte]=${last7}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.jwtToken}`,
              },
            }
          );

          if (!res.error) {
            setMemberNumber(res.length);
          } else {
            toast.error(res.error.message);
          }
        } catch (e) {
          console.error(e);
        }
      };

      const GetApplyMentor = async () => {
        try {
          const res = await useFetch(
            `${process.env.REACT_APP_STRAPI}/users?start=${start}&limit=${limit}&sort[0]=createdAt:desc&populate[0]=User_profile`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.jwtToken}`,
              },
            }
          );

          if (!res.error) {
            setMemberList(res);
          } else {
            toast.error(res.error.message);
          }
        } catch (e) {
          console.error(e);
        }
      };

      GetMember();
      GetApplyMentor();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    const now = new Date().getTime();
    const sevenDaysBefore = now - 7 * 24 * 60 * 60 * 1000;

    const filter = memberList.filter((list: any) => {
      const registrationTime = new Date(list.createdAt);
      return new Date(sevenDaysBefore) <= registrationTime;
    });

    setNewMemberList(filter);
  }, [memberList]);

  const renderNewMemberList = () => {
    return newMemberList.map((list: any) => {
      return (
        <div
          className={styles.memberCard}
          key={list.id}
          onClick={() => navigate(`/memberDetail/${list.id}`)}
        >
          <p>{list.id}</p>
          <p>{moment(list.createdAt).format("LL")}</p>
          {list.User_profile.first_name} {list.User_profile.last_name}
        </div>
      );
    });
  };

  return (
    <>
      {renderNewMemberList()}

      <div className={styles.footer}>
        <div onClick={() => setPageNumber(pageNumber - 1)}>
          {pageNumber === 1 ? <span style={{ color: "white" }}>上一批</span> : <span>上一批</span>}
        </div>

        <div>第 {pageNumber} 頁</div>

        <div onClick={() => setPageNumber(pageNumber + 1)}>
          {memberNumber % 5 !== 0 && memberList.length * pageNumber < memberNumber ? (
            <span>下一批</span>
          ) : (
            <span style={{ color: "white" }}>下一批</span>
          )}
        </div>
      </div>
    </>
  );
}

export default NewRegistration;
