import React from "react";
import MemberDetail from "../../components/memberInfo/MemberDetail";

function MemberDetailPage() {
  return (
    <div>
      <MemberDetail />
    </div>
  );
}

export default MemberDetailPage;
