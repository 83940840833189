import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import { useAppDispatch } from "../../redux/Hook";
import { loginThunk } from "../../pages/auth/AuthSlice";
import { useEffect } from "react";

import styles from "../../styles/Login.module.scss";

export default function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values: any) => {
    const registrationInfo = {
      email: values.email,
      password: values.password,
    };

    dispatch(loginThunk(registrationInfo))
      .unwrap()
      .then(() => navigate(0))
      .catch(() => {
        toast.error("Unsuccessful login, please check your email and password!");
      });
  };

  return (
    <>
      <div className={styles.login}>
        <Toaster position="top-right" reverseOrder={false} />

        <form onSubmit={formik.handleSubmit}>
          <label htmlFor="email">Email Address</label>
          <input
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />

          <label htmlFor="password">Password</label>
          <input
            id="password"
            name="password"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password}
          />

          <button type="submit">Submit</button>
        </form>
      </div>
    </>
  );
}
