import { useEffect, useState } from "react";
import { useDecodeToken } from "../../hooks/useDecodeToken";
import { useFetch } from "../../hooks/useFetch";
import styles from "../../styles/PopUp.module.scss";
import { TbPencilCancel } from "react-icons/tb";
import { LuUpload } from "react-icons/lu";
import toast from "react-hot-toast";
import WorkExpEdit from "./WorkExpEdit";
import { fetcher } from "../../lib/fetcher";
import { useParams } from "react-router-dom";

function PopUp({ setPopUp, type, applyInfo, targetId }: any) {
  const userInfo = useDecodeToken();
  const applicationId = useParams();
  const mentorId = Number(applicationId.id);

  const [editInfo, setEditInfo] = useState([]);
  const [mentorSelection, setMentorSelection] = useState<any>(null);
  const [isActive, setIsActive] = useState(false);

  const [servicesName, setServicesName] = useState("");
  const [servicesDuration, setServicesDuration] = useState("");
  const [servicesPrice, setServicesPrice] = useState("");
  // const [isServicesActive, setIsServicesActive] = useState(false);

  useEffect(() => {
    if (userInfo) {
      if (type === "skills") {
        const GetInfo = async () => {
          try {
            const res = await useFetch(`${process.env.REACT_APP_STRAPI}/${type}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.jwtToken}`,
              },
            });

            if (!res.error) {
              setEditInfo(res.data);
              setMentorSelection(applyInfo.Skills.data.map((item: any) => item.id));
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetInfo();
      } else if (type === "description") {
        setEditInfo(applyInfo);
      } else if (type === "services") {
        const GetInfo = async () => {
          try {
            const res = await useFetch(`${process.env.REACT_APP_STRAPI}/${type}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.jwtToken}`,
              },
            });

            if (!res.error) {
              setEditInfo(res.data);
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetInfo();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  // useEffect(() => {
  //   if (userInfo) {
  //     const GetMentorService = async () => {
  //       try {
  //         const res = await useFetch(
  //           `${process.env.REACT_APP_STRAPI}/services?populate[0]=mentor_services.Mentor`,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${userInfo.jwtToken}`,
  //             },
  //           }
  //         );

  //         if (!res.error) {
  //           const service = res.data
  //             .map((data: any) =>
  //               data.attributes.mentor_services.data.filter((data: any) => {
  //                 return data.attributes.Mentor.data.id === mentorId;
  //               })
  //             )
  //             .flat()
  //             .map((item: any) => item.id);
  //           setMentorService(service);
  //         }
  //       } catch (e) {
  //         console.error(e);
  //       }
  //     };
  //     GetMentorService();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [applicationId]);

  const checking = (id: number | null) => {
    if (type === "skills") {
      const index = mentorSelection.indexOf(id);
      if (index !== -1) {
        setMentorSelection(mentorSelection.filter((item: any) => item !== id));
      } else {
        setMentorSelection([...mentorSelection, id]);
      }
    } else if (type === "services") {
      const index = mentorSelection.indexOf(id);
      if (index !== -1) {
        setMentorSelection(mentorSelection.filter((item: any) => item !== id));
      } else {
        setMentorSelection([...mentorSelection, id]);
      }
    }
  };

  const submit = async () => {
    if (!userInfo) return;

    const fieldMap = {
      skills: "Skills",
      description: "description",
      workExp: "Mentor_experiences",
      eduExp: "Mentor_experiences",
      services: "is_delete",
    };

    const field = fieldMap[type as keyof typeof fieldMap];

    if (!field) return;

    if (servicesName !== "" && servicesDuration !== "" && servicesPrice !== "") {
      setMentorSelection("services");
    } else if (
      !mentorSelection ||
      mentorSelection.length < 1 ||
      mentorSelection === "" ||
      mentorSelection === null
    ) {
      toast.error("需填寫所有事項1");
      return;
    }

    if (type === "workExp" || type === "eduExp") {
      if (mentorSelection.title_name === "" || mentorSelection.org_name === "") {
        toast.error("需填寫所有事項2");
        return;
      } else if (mentorSelection.start_year === 0 || mentorSelection.start_month === 0) {
        toast.error("需填寫所有事項3");
        return;
      } else if (!isActive && (mentorSelection.end_year === 0 || mentorSelection.end_month === 0)) {
        toast.error("需填寫所有事項4");
        return;
      } else if (
        mentorSelection.end_year > 0 &&
        mentorSelection.end_year < mentorSelection.start_year
      ) {
        toast.error("結束年份不能早於開始年份");
        return;
      } else {
        const UpdateMentorInfo = async () => {
          try {
            const res = await useFetch(`${process.env.REACT_APP_STRAPI}/mentor-experiences`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.jwtToken}`,
              },
              body: JSON.stringify({
                data: {
                  type: `${type === "workExp" ? "work" : "education"}`,
                  title_name: mentorSelection.title_name,
                  org_name: mentorSelection.org_name,
                  start_year: mentorSelection.start_year,
                  start_month: mentorSelection.start_month,
                  end_year: mentorSelection.end_year,
                  end_month: mentorSelection.end_month,
                  Mentor: targetId,
                },
              }),
            });

            if (!res.error) {
              toast.success("已更改！");
              setPopUp(false);
            }
          } catch (e) {
            console.error(e);
          }
        };
        UpdateMentorInfo();
        return;
      }
    }

    if (type === "skills" || type === "description") {
      try {
        const res = await fetcher(`${process.env.REACT_APP_STRAPI}/mentor-profiles/${targetId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo.jwtToken}`,
          },
          body: JSON.stringify({
            data: {
              [field]: mentorSelection,
            },
          }),
        });

        if (!res.error) {
          toast.success("已更改！");
          setPopUp(false);
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (type === "services") {
      try {
        const res = await fetcher(`${process.env.REACT_APP_STRAPI}/mentor-services/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo.jwtToken}`,
          },
          body: JSON.stringify({
            data: {
              Service: servicesName,
              duration: servicesDuration,
              price: servicesPrice,
              // is_active: isServicesActive,
              is_active: true,
              Mentor: mentorId,
            },
          }),
        });

        if (res.error) {
          toast.error(res.error.message);
          throw new Error(res.error.message);
        }

        toast.success("已新增！");
        setPopUp(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderInfo = () => {
    if (editInfo) {
      if (type === "skills") {
        return editInfo.map((item: any) => {
          return (
            <div className={styles.edit} key={item.id}>
              <div className={styles.subTitle}>{item.attributes.name}</div>
              <div
                className={mentorSelection.includes(item.id) ? styles.block : styles.deactivate}
                onClick={() => checking(item.id)}
              >
                {item.attributes.description}
              </div>
            </div>
          );
        });
      } else if (type === "description") {
        return (
          <div className={styles.edit}>
            <input
              id="description"
              type="text"
              placeholder={applyInfo.description}
              onChange={(e) => setMentorSelection(e.target.value)}
            />
          </div>
        );
      } else if (type === "workExp" || type === "eduExp") {
        return (
          <div className={styles.edit}>
            <WorkExpEdit
              setMentorSelection={setMentorSelection}
              type={type}
              isActive={isActive}
              setIsActive={setIsActive}
            />
          </div>
        );
      } else if (type === "timetable") {
        return <div className={styles.edit}></div>;
      } else if (type === "services") {
        return (
          <div className={styles.edit}>
            <div className={styles.service}>
              <p>提供服務*</p>
              <select
                name="serviceName"
                id="serviceName"
                onChange={(event) => {
                  setServicesName(event.target.value);
                }}
              >
                <option value="">選擇服務</option>

                {editInfo.map((service: any) => (
                  <option key={service.attributes.name} value={service.id}>
                    {service.attributes.name}
                  </option>
                ))}
              </select>
            </div>

            <div className={styles.two}>
              <div className={styles.service}>
                <p>時長*</p>
                <select
                  name="serviceDuration"
                  id="serviceDuration"
                  onChange={(event) => {
                    setServicesDuration(event.target.value);
                  }}
                >
                  {servicesName === "" && <option value="">選擇時長</option>}
                  {servicesName !== "" && (
                    <>
                      <option value="">選擇時長</option>
                      <option value="20">20分鐘</option>
                      <option value="40">40分鐘</option>
                      <option value="60">60分鐘</option>
                    </>
                  )}
                </select>
              </div>

              <div className={styles.service}>
                <p>價錢*</p>
                <select
                  name="serviceDuration"
                  id="serviceDuration"
                  onChange={(event) => {
                    setServicesPrice(event.target.value);
                  }}
                >
                  {servicesDuration === "" && <option value="">選擇售價</option>}

                  {servicesDuration === "20" && (
                    <>
                      <option value="">選擇售價</option>
                      <option value="250">$250</option>
                      <option value="500">$500</option>
                    </>
                  )}

                  {servicesDuration === "40" && (
                    <>
                      <option value="">選擇售價</option>
                      <option value="500">$500</option>
                      <option value="1000">$1000</option>
                    </>
                  )}

                  {servicesDuration === "60" && (
                    <>
                      <option value="">選擇售價</option>
                      <option value="750">$750</option>
                      <option value="1500">$1500</option>
                    </>
                  )}
                </select>
              </div>
            </div>

            {/* <div className={styles.trigger}>
              <p>暫時不提供服務</p>
              <label>
                <input
                  type="checkbox"
                  id="isActive"
                  name="isActive"
                  checked={isServicesActive === true}
                  onChange={(e) => {
                    setIsServicesActive(!isServicesActive);
                  }}
                />
              </label>
            </div> */}
          </div>
        );
      }
    }
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.info}>{renderInfo()}</div>
      <div className={styles.button}>
        <div onClick={() => setPopUp(false)}>
          <TbPencilCancel />
        </div>
        <div onClick={() => submit()}>
          <LuUpload />
        </div>
      </div>
    </div>
  );
}

export default PopUp;
