import jwt_decode from "jwt-decode";

interface JWT {
  id: number;
  iat: number;
  exp: number;
}

export function useDecodeToken() {
  const token = localStorage.getItem("token");

  if (token) {
    const decode: JWT = jwt_decode(`${token}`);
    const userInfo = { id: decode.id, jwtToken: token };
    return userInfo;
  } else {
    return false;
  }
}
