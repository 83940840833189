import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFetch } from "../../hooks/useFetch";
import toast, { Toaster } from "react-hot-toast";
import { useDecodeToken } from "../../hooks/useDecodeToken";
import styles from "../../styles/PlatformNotice.module.scss";
import { AiOutlineEdit } from "react-icons/ai";
import { TbPencilCancel } from "react-icons/tb";
import { LuUpload } from "react-icons/lu";

function PlatformNotice() {
  const userInfo = useDecodeToken();
  const navigate = useNavigate();

  const [notice, setNotice] = useState([]);
  const [editChiTitle, setEditChiTitle] = useState(false);
  const [editChiDescription, setEditChiDescription] = useState(false);
  const [editEngTitle, setEditEngTitle] = useState(false);
  const [editEngDescription, setEditEngDescription] = useState(false);

  const [updateChiTitle, setUpdateChiTitle] = useState("");
  const [updateChiDescription, setUpdateChiDescription] = useState("");
  const [updateEngTitle, setUpdateEngTitle] = useState("");
  const [updateEngDescription, setUpdateEngDescription] = useState("");

  useEffect(() => {
    if (userInfo) {
      const GetPlatformNotice = async () => {
        try {
          const res = await useFetch(`${process.env.REACT_APP_STRAPI}/platform-notices`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.jwtToken}`,
            },
          });

          if (!res.error) {
            setNotice(res.data);
          } else {
            toast.error(res.error.message);
          }
        } catch (e) {
          console.error(e);
        }
      };
      GetPlatformNotice();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editChiTitle, editChiDescription, editEngTitle, editEngDescription]);

  const handleSubmit = (key: string, detail: string) => {
    if (!userInfo) return;

    const fieldMap = {
      updateChiTitle: "title_chi",
      updateChiDescription: "description_chi",
      updateEngTitle: "title_eng",
      updateEngDescription: "description_eng",
    };

    const field = fieldMap[key as keyof typeof fieldMap];

    if (!field) return;

    const UpdateNotice = async () => {
      try {
        const res = await useFetch(`${process.env.REACT_APP_STRAPI}/platform-notices/1`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo.jwtToken}`,
          },
          body: JSON.stringify({
            data: {
              [field]: detail,
            },
          }),
        });

        if (res.error) {
          toast.error(res.error.message);
        }
        toast.success("已更改！");
        navigate(-1);
      } catch (e) {
        console.error(e);
      }
    };
    UpdateNotice();
  };

  const renderNotice = () => {
    if (notice) {
      return notice.map((item: any) => {
        return (
          <>
            <div className={styles.notice} key={item.id}>
              <div className={styles.edit}>
                <div className={styles.subTitle}>編輯標題</div>
                <div className={styles.block}>
                  {editChiTitle ? (
                    <>
                      <input
                        id="title_chi"
                        type="text"
                        placeholder={item.attributes.title_chi}
                        onChange={(e) => setUpdateChiTitle(e.target.value)}
                      />

                      <div className={styles.button}>
                        <div
                          onClick={() => {
                            handleSubmit(`updateChiTitle`, updateChiTitle);
                            setEditChiTitle(false);
                          }}
                        >
                          <LuUpload />
                        </div>
                        <div onClick={() => setEditChiTitle(false)}>
                          <TbPencilCancel />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <span>{item.attributes.title_chi}</span>
                      <div onClick={() => setEditChiTitle(true)}>
                        <AiOutlineEdit />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className={styles.edit}>
                <div className={styles.subTitle}>編輯內容</div>
                <div className={styles.block}>
                  {editChiDescription ? (
                    <>
                      <input
                        id="description_chi"
                        type="text"
                        placeholder={item.attributes.description_chi}
                        onChange={(e) => setUpdateChiDescription(e.target.value)}
                      />

                      <div className={styles.button}>
                        <div
                          onClick={() => {
                            handleSubmit(`updateChiDescription`, updateChiDescription);
                            setEditChiDescription(false);
                          }}
                        >
                          <LuUpload />
                        </div>
                        <div onClick={() => setEditChiDescription(false)}>
                          <TbPencilCancel />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <span>{item.attributes.description_chi}</span>
                      <div onClick={() => setEditChiDescription(true)}>
                        <AiOutlineEdit />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className={styles.edit}>
                <div className={styles.subTitle}>Edit Title</div>
                <div className={styles.block}>
                  {editEngTitle ? (
                    <>
                      <input
                        id="title_eng"
                        type="text"
                        placeholder={item.attributes.title_eng}
                        onChange={(e) => setUpdateEngTitle(e.target.value)}
                      />

                      <div className={styles.button}>
                        <div
                          onClick={() => {
                            handleSubmit(`updateEngTitle`, updateEngTitle);
                            setEditEngTitle(false);
                          }}
                        >
                          <LuUpload />
                        </div>
                        <div onClick={() => setEditEngTitle(false)}>
                          <TbPencilCancel />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <span>{item.attributes.title_eng}</span>
                      <div onClick={() => setEditEngTitle(true)}>
                        <AiOutlineEdit />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className={styles.edit}>
                <div className={styles.subTitle}>Edit Description</div>
                <div className={styles.block}>
                  {editEngDescription ? (
                    <>
                      <input
                        id="description_eng"
                        type="text"
                        placeholder={item.attributes.description_eng}
                        onChange={(e) => setUpdateEngDescription(e.target.value)}
                      />

                      <div className={styles.button}>
                        <div
                          onClick={() => {
                            handleSubmit(`updateEngDescription`, updateEngDescription);
                            setEditEngDescription(false);
                          }}
                        >
                          <LuUpload />
                        </div>
                        <div onClick={() => setEditEngDescription(false)}>
                          <TbPencilCancel />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <span>{item.attributes.description_eng}</span>
                      <div onClick={() => setEditEngDescription(true)}>
                        <AiOutlineEdit />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      });
    }
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      {renderNotice()}
    </>
  );
}

export default PlatformNotice;
