import { useNavigate } from "react-router";
import ApplyMentor from "../../components/applyMentor/ApplyMentor";

import { IoReturnUpBack } from "react-icons/io5";
import { LiaHomeSolid } from "react-icons/lia";

import styles from "../../styles/MemberInfo.module.scss";

function ApplyMentorPage() {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.memberInfo}>
        <div className={styles.header}>
          <div onClick={() => navigate(-1)}>
            <IoReturnUpBack />
          </div>
          <div onClick={() => navigate("/")}>
            <LiaHomeSolid />
          </div>
        </div>

        <div className={styles.memberList}>
          <ApplyMentor />
        </div>
      </div>
    </>
  );
}

export default ApplyMentorPage;
