import Landing from "../components/Landing";

function LandingPage() {
  return (
    <>
      <Landing />
    </>
  );
}

export default LandingPage;
