export const bankList = [
  {
    id: "003",
    name_en: "STANDARD CHARTERED BANK (HONG KONG) LIMITED",
    name_ch: "渣打銀行(香港)有限公司",
  },
  {
    id: "004",
    name_en: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED (HSBC)",
    name_ch: "香港上海滙豐銀行有限公司",
  },
  {
    id: "005",
    name_en: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK",
    name_ch: "東方匯理銀行",
  },
  {
    id: "006",
    name_en: "CITIBANK, N.A.",
    name_ch: "花旗銀行",
  },
  {
    id: "007",
    name_en: "JPMORGAN CHASE BANK, N.A.",
    name_ch: "摩根大通銀行",
  },
  {
    id: "008",
    name_en: "NATWEST MARTETS PLC",
    name_ch: "國民西敏寺資本市場銀行有限公司",
  },
  {
    id: "009",
    name_en: "CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED",
    name_ch: "中國建設銀行(亞洲)股份有限公司",
  },
  {
    id: "012",
    name_en: "BANK OF CHINA (HONG KONG) LIMITED",
    name_ch: "中國銀行(香港)有限公司",
  },
  {
    id: "015",
    name_en: "THE BANK OF EAST ASIA, LIMITED",
    name_ch: "東亞銀行有限公司",
  },
  {
    id: "016",
    name_en: "DBS BANK (HONG KONG) LIMITED",
    name_ch: "星展銀行(香港)有限公司",
  },
  {
    id: "018",
    name_en: "CHINA CITIC BANK INTERNATIONAL LIMITED",
    name_ch: "中信銀行(國際)有限公司",
  },
  {
    id: "020",
    name_en: "CMB WING LUNG BANK LIMITED.",
    name_ch: "招商永隆銀行有限公司",
  },
  {
    id: "022",
    name_en: "OVERSEA-CHINESE BANKING CORPORATION LTD.",
    name_ch: "華僑銀行",
  },
  {
    id: "024",
    name_en: "HANG SENG BANK LTD.",
    name_ch: "恒生銀行有限公司",
  },
  {
    id: "025",
    name_en: "SHANGHAI COMMERCIAL BANK LTD.",
    name_ch: "上海商業銀行有限公司",
  },
  {
    id: "027",
    name_en: "BANK OF COMMUNICATIONS CO., LTD",
    name_ch: "交通銀行股份有限公司",
  },
  {
    id: "028",
    name_en: "PUBLIC BANK (HONG KONG) LIMITED",
    name_ch: "大眾銀行(香港)有限公司",
  },
  {
    id: "035",
    name_en: "OCBC WING HANG BANK LIMITED",
    name_ch: "華僑永亨銀行有限公司",
  },
  {
    id: "038",
    name_en: "TAI YAU BANK LTD.",
    name_ch: "大有銀行有限公司",
  },
  {
    id: "039",
    name_en: "CHIYU BANKING CORPORATION LTD.",
    name_ch: "集友銀行有限公司",
  },
  {
    id: "040",
    name_en: "DAH SING BANK, LTD.",
    name_ch: "大新銀行有限公司",
  },
  {
    id: "041",
    name_en: "CHONG HING BANK LIMITED",
    name_ch: "創興銀行有限公司",
  },
  {
    id: "043",
    name_en: "NANYANG COMMERCIAL BANK, LTD.",
    name_ch: "南洋商業銀行有限公司",
  },
  {
    id: "045",
    name_en: "UCO BANK",
    name_ch: "合眾銀行",
  },
  {
    id: "046",
    name_en: "KEB HANA BANK",
    name_ch: "韓國外換銀行",
  },
  {
    id: "047",
    name_en: "MUFG BANK, LTD.",
    name_ch: "東京三菱銀行",
  },
  {
    id: "049",
    name_en: "BANGKOK BANK PUBLIC COMPANY LIMITED",
    name_ch: "盤谷銀行",
  },
  {
    id: "050",
    name_en: "INDIAN OVERSEAS BANK",
    name_ch: "印度海外銀行",
  },
  {
    id: "052",
    name_en: "DBS BANK LTD.",
    name_ch: "星展銀行（香港）有限公司",
  },
  {
    id: "054",
    name_en: "DEUTSCHE BANK AG",
    name_ch: "德意志銀行",
  },
  {
    id: "055",
    name_en: "BANK OF AMERICA, N.A.",
    name_ch: "美國銀行",
  },
  {
    id: "056",
    name_en: "BNP PARIBAS",
    name_ch: "法國巴黎銀行",
  },
  {
    id: "058",
    name_en: "BANK OF INDIA",
    name_ch: "印度銀行",
  },
  {
    id: "060",
    name_en: "NATIONAL BANK OF PAKISTAN",
    name_ch: "巴基斯坦國民銀行",
  },
  {
    id: "061",
    name_en: "TAI SANG BANK LIMITED",
    name_ch: "大生銀行有限公司",
  },
  {
    id: "063",
    name_en: "MALAYAN BANKING BERHAD (MAYBANK)",
    name_ch: "馬來亞銀行",
  },
  {
    id: "065",
    name_en: "SUMITOMO MITSUI BANKING CORPORATION",
    name_ch: "三井住友銀行",
  },
  {
    id: "066",
    name_en: "PT. BANK NEGARA INDONESIA (PERSERO) TBK.",
    name_ch: "印尼國家銀行",
  },
  {
    id: "067",
    name_en: "BDO UNIBANK, INC.",
    name_ch: "金融銀行有限公司",
  },
  {
    id: "071",
    name_en: "UNITED OVERSEAS BANK LTD.",
    name_ch: "大華銀行有限公司",
  },
  {
    id: "072",
    name_en: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LIMITED",
    name_ch: "中國工商銀行（亞洲）有限公",
  },
  {
    id: "074",
    name_en: "BARCLAYS BANK PLC.",
    name_ch: "巴克萊銀行",
  },
  {
    id: "076",
    name_en: "THE BANK OF NOVA SCOTIA",
    name_ch: "加拿大豐業銀行",
  },
  {
    id: "080",
    name_en: "ROYAL BANK OF CANADA",
    name_ch: "加拿大皇家銀行",
  },
  {
    id: "081",
    name_en: "SOCIETE GENERALE",
    name_ch: "法國興業銀行",
  },
  {
    id: "082",
    name_en: "STATE BANK OF INDIA",
    name_ch: "印度國家銀行",
  },
  {
    id: "085",
    name_en: "THE TORONTO-DOMINION BANK",
    name_ch: "多倫多道明銀行",
  },
  {
    id: "086",
    name_en: "086 BANK OF MONTREAL",
    name_ch: "加拿大滿地可銀行",
  },
  {
    id: "092",
    name_en: "CANADIAN IMPERIAL BANK OF COMMERCE",
    name_ch: "加拿大帝國商業銀行",
  },
  {
    id: "097",
    name_en: "COMMERZBANK AG",
    name_ch: "德國商業銀行",
  },
  {
    id: "103",
    name_en: "UBS AG, HONG KONG",
    name_ch: "瑞士銀行",
  },
  {
    id: "106",
    name_en: "HSBC BANK USA, N.A.",
    name_ch: "美國滙豐銀行",
  },
  {
    id: "109",
    name_en: "MIZUHO BANK, LTD., HONG KONG BRANCH",
    name_ch: "瑞穗銀行",
  },
  {
    id: "113",
    name_en: "DZ BANK AG DEUTSCHE ZENTRAL-GENOSSENSCHAFTSBANK",
    name_ch: "德國中央合作銀行",
  },
  {
    id: "118",
    name_en: "WOORI BANK",
    name_ch: "友利銀行",
  },
  {
    id: "119",
    name_en: "PHILIPPINE NATIONAL BANK",
    name_ch: "菲律賓國家銀行",
  },
  {
    id: "128",
    name_en: "FUBON BANK (HONG KONG) LIMITED",
    name_ch: "富邦銀行(香港)有限公司",
  },
  {
    id: "138",
    name_en: "MITSUBISHI UFJ TRUST AND BANKING CORPORATION",
    name_ch: "三菱信託銀行",
  },
  {
    id: "139",
    name_en: "THE BANK OF NEW YORK MELLON",
    name_ch: "紐約梅隆銀行有限公司",
  },
  {
    id: "145",
    name_en: "ING BANK N.V.",
    name_ch: "",
  },
  {
    id: "147",
    name_en: "BANCO BILBAO VIZCAYA ARGENTARIA S.A.",
    name_ch: "西班牙對外銀行",
  },
  {
    id: "150",
    name_en: "NATIONAL AUSTRALIA BANK, LIMITED",
    name_ch: "澳大利亞國民銀行",
  },
  {
    id: "151",
    name_en: "WESTPAC BANKING CORPORATION",
    name_ch: "西太平洋銀行",
  },
  {
    id: "152",
    name_en: "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED",
    name_ch: "澳新銀行集團有限公司",
  },
  {
    id: "153",
    name_en: "COMMONWEALTH BANK OF AUSTRALIA",
    name_ch: "澳洲聯邦銀行",
  },
  {
    id: "161",
    name_en: "INTESA SANPAOLO S.P.A.",
    name_ch: "意大利聯合聖保羅銀行股份有限公司",
  },
  {
    id: "164",
    name_en: "UNICREDIT BANK AG",
    name_ch: "裕信(德國)銀行股份有限公司",
  },
  {
    id: "165",
    name_en: "SVENSKA HANDELSBANKEN AB (PUBL)",
    name_ch: "瑞典商業銀行",
  },
  {
    id: "170",
    name_en: "THE CHIBA BANK LTD.",
    name_ch: "千葉銀行",
  },
  {
    id: "178",
    name_en: "KBC BANK N.V., HONG KONG BRANCH",
    name_ch: "比利時聯合銀行",
  },
  {
    id: "180",
    name_en: "WELLS FARGO BANK, N.A., HONG KONG BRANCH",
    name_ch: "富國銀行香港分行",
  },
  {
    id: "183",
    name_en: "COÖPERATIEVE RABOBANK U.A.",
    name_ch: "",
  },
  {
    id: "185",
    name_en: "DBS BANK LTD., HONG KONG BRANCH",
    name_ch: "星展銀行(香港)有限公司",
  },
  {
    id: "186",
    name_en: "THE SHIZUOKA BANK LTD.",
    name_ch: "靜岡銀行",
  },
  {
    id: "188",
    name_en: "THE HACHIJUNI BANK, LTD.",
    name_ch: "八十二銀行",
  },
  {
    id: "198",
    name_en: "HUA NAN COMMERCIAL BANK, LTD.",
    name_ch: "華南商業銀行股份有限公司",
  },
  {
    id: "199",
    name_en: "THE SHIGA BANK, LTD.",
    name_ch: "滋賀銀行",
  },
  {
    id: "201",
    name_en: "BANK OF TAIWAN",
    name_ch: "臺灣銀行股份有限公司",
  },
  {
    id: "202",
    name_en: "THE CHUGOKU BANK LIMITED",
    name_ch: "",
  },
  {
    id: "203",
    name_en: "FIRST COMMERCIAL BANK",
    name_ch: "第一商業銀行股份有限公司",
  },
  {
    id: "205",
    name_en: "COUTTS &amp; CO. LTD.",
    name_ch: "顧資銀行",
  },
  {
    id: "206",
    name_en: "CHANG HWA COMMERCIAL BANK, LTD.",
    name_ch: "彰化商業銀行股份有限公司",
  },
  {
    id: "210",
    name_en: "NATIXIS",
    name_ch: "法國外貿銀行",
  },
  {
    id: "214",
    name_en: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED",
    name_ch: "中國工商銀行股份有限公司",
  },
  {
    id: "220",
    name_en: "STATE STREET BANK AND TRUST COMPANY",
    name_ch: "",
  },
  {
    id: "221",
    name_en: "CHINA CONSTRUCTION BANK CORPORATION",
    name_ch: "中國建設銀行股份有限公司",
  },
  {
    id: "222",
    name_en: "AGRICULTURAL BANK OF CHINA LIMITED",
    name_ch: "中國農業銀行股份有限公司",
  },
  {
    id: "227",
    name_en: "ERSTE GROUP BANK AG",
    name_ch: "",
  },
  {
    id: "229",
    name_en: "CTBC BANK CO., LTD.",
    name_ch: "中國信託商業銀行股份有限公司",
  },
  {
    id: "230",
    name_en: "TAIWAN BUSINESS BANK, LTD.",
    name_ch: "臺灣中小企業銀行股份有限公司",
  },
  {
    id: "233",
    name_en: "CREDIT SUISSE AG",
    name_ch: "",
  },
  {
    id: "235",
    name_en: "HSBC PRIVATE BANK (SUISSE) SA",
    name_ch: "滙豐私人銀行(瑞士)有限公司",
  },
  {
    id: "236",
    name_en: "CATHAY UNITED BANK COMPANY, LIMITED",
    name_ch: "國泰世華商業銀行股份有限公司",
  },
  {
    id: "237",
    name_en: "EFG BANK AG",
    name_ch: "瑞士盈豐銀行股份有限公司",
  },
  {
    id: "238",
    name_en: "CHINA MERCHANTS BANK CO., LTD.",
    name_ch: "招商銀行股份有限公司",
  },
  {
    id: "239",
    name_en: "TAIPEI FUBON COMMERCIAL BANK CO., LTD.",
    name_ch: "台北富邦商業銀行股份有限公司",
  },
  {
    id: "241",
    name_en: "BANK SINOPAC",
    name_ch: "永豐商業銀行股份有限公司",
  },
  {
    id: "242",
    name_en: "MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD.",
    name_ch: "兆豐國際商業銀行股份有限公司",
  },
  {
    id: "243",
    name_en: "E.SUN COMMERCIAL BANK, LTD.",
    name_ch: "玉山商業銀行股份有限公司",
  },
  {
    id: "245",
    name_en: "TAISHIN INTERNATIONAL BANK CO., LTD.",
    name_ch: "台新國際商業銀行股份有限公司",
  },
  {
    id: "248",
    name_en: "HONG LEONG BANK BERHAD",
    name_ch: "豐隆銀行有限公司",
  },
  {
    id: "249",
    name_en: "STANDARD CHARTERED BANK HONG KONG BRANCH",
    name_ch: "渣打銀行",
  },
  {
    id: "250",
    name_en: "CITIBANK (HONG KONG) LIMITED",
    name_ch: "花旗銀行(香港)有限公司",
  },
  {
    id: "251",
    name_en: "ICICI BANK LIMITED",
    name_ch: "",
  },
  {
    id: "254",
    name_en: "MELLI BANK PLC",
    name_ch: "",
  },
  {
    id: "258",
    name_en: "EAST WEST BANK",
    name_ch: "華美銀行",
  },
  {
    id: "259",
    name_en: "BANK OF BARODA",
    name_ch: "巴魯達銀行",
  },
  {
    id: "260",
    name_en: "FAR EASTERN INTERNATIONAL BANK",
    name_ch: "遠東國際商業銀行股份有限公司",
  },
  {
    id: "261",
    name_en: "AXIS BANK LIMITED",
    name_ch: "",
  },
  {
    id: "262",
    name_en: "CANARA BANK",
    name_ch: "",
  },
  {
    id: "263",
    name_en: "CATHAY BANK",
    name_ch: "國泰銀行",
  },
  {
    id: "264",
    name_en: "LAND BANK OF TAIWAN CO., LTD.",
    name_ch: "臺灣土地銀行股份有限公司",
  },
  {
    id: "265",
    name_en: "TAIWAN COOPERATIVE BANK, LTD.",
    name_ch: "合作金庫商業銀行股份有限公司",
  },
  {
    id: "266",
    name_en: "PUNJAB NATIONAL BANK",
    name_ch: "",
  },
  {
    id: "267",
    name_en: "BANCO SANTANDER, S.A.",
    name_ch: "西班牙桑坦德銀行有限公司",
  },
  {
    id: "268",
    name_en: "UNION BANK OF INDIA",
    name_ch: "",
  },
  {
    id: "269",
    name_en: "SHANGHAI COMMERCIAL &amp; SAVINGS BANK, LTD. (THE)",
    name_ch: "上海商業儲蓄銀行股份有限公司",
  },
  {
    id: "271",
    name_en: "INDUSTRIAL BANK OF KOREA",
    name_ch: "",
  },
  {
    id: "272",
    name_en: "BANK OF SINGAPORE LIMITED",
    name_ch: "新加坡銀行有限公司",
  },
  {
    id: "273",
    name_en: "SHINHAN BANK",
    name_ch: "",
  },
  {
    id: "274",
    name_en: "O-BANK CO., LTD.",
    name_ch: "王道商業銀行股份有限公司",
  },
  {
    id: "275",
    name_en: "BNP PARIBAS SECURITIES SERVICES",
    name_ch: "",
  },
  {
    id: "276",
    name_en: "CHINA DEVELOPMENT BANK CORPORATION",
    name_ch: "國家開發銀行股份有限公司",
  },
  {
    id: "277",
    name_en: "NATIONAL BANK OF ABU DHABI",
    name_ch: "",
  },
  {
    id: "278",
    name_en: "BANK J. SAFRA SARASIN AG",
    name_ch: "瑞士嘉盛銀行",
  },
  {
    id: "307",
    name_en: "ABN AMRO BANK N.V.",
    name_ch: "荷蘭銀行",
  },
  {
    id: "308",
    name_en: "HDFC BANK LIMITED",
    name_ch: "",
  },
  {
    id: "309",
    name_en: "UNION BANCAIRE PRIVEE, UBP SA",
    name_ch: "",
  },
  {
    id: "316",
    name_en: "SKANDINAVISKA ENSKILDA BANKEN AB",
    name_ch: "",
  },
  {
    id: "320",
    name_en: "BANK JULIUS BAER &amp; CO. LTD.",
    name_ch: "瑞士寶盛私人銀行",
  },
  {
    id: "324",
    name_en: "CREDIT INDUSTRIEL ET COMMERCIAL",
    name_ch: "法國工商銀行",
  },
  {
    id: "337",
    name_en: "TAIWAN SHIN KONG COMMERCIAL BANK CO., LTD.",
    name_ch: "臺灣新光商業銀行股份有限公司",
  },
  {
    id: "338",
    name_en: "BANK OF CHINA LIMITED",
    name_ch: "中國銀行股份有限公司",
  },
  {
    id: "339",
    name_en: "CA INDOSUEZ (SWITZERLAND) SA",
    name_ch: "",
  },
  {
    id: "341",
    name_en: "ICBC STANDARD BANK PLC",
    name_ch: "",
  },
  {
    id: "342",
    name_en: "LGT BANK AG",
    name_ch: "LGT皇家銀行(香港)",
  },
  {
    id: "344",
    name_en: "MACQUARIE BANK LIMITED",
    name_ch: "麥格理銀行有限公司",
  },
  {
    id: "345",
    name_en: "SHANGHAI PUDONG DEVELOPMENT BANK CO. LTD.",
    name_ch: "上海浦東發展銀行股份有限公司",
  },
  {
    id: "353",
    name_en: "CHINA MINSHENG BANKING CORP., LTD.",
    name_ch: "中國民生銀行股份有限公司",
  },
  {
    id: "357",
    name_en: "PICTET &amp; CIE (EUROPE) S.A.",
    name_ch: "",
  },
  {
    id: "360",
    name_en: "NATWEST MARKETS N.V.",
    name_ch: "",
  },
  {
    id: "368",
    name_en: "CHINA EVERBRIGHT BANK CO., LTD",
    name_ch: "中國光大銀行股份有限公司",
  },
  {
    id: "371",
    name_en: "SUMITOMO MITSUI TRUST BANK, LIMITED",
    name_ch: "三井住友信託銀行",
  },
  {
    id: "372",
    name_en: "BANK OF SHANGHAI (HONG KONG) LIMITED",
    name_ch: "上海銀行(香港)有限公司",
  },
  {
    id: "374",
    name_en: "CIMB BANK BERHAD",
    name_ch: "",
  },
  {
    id: "377",
    name_en: "INDUSTRIAL BANK CO., LTD.",
    name_ch: "興業銀行股份有限公司",
  },
  {
    id: "378",
    name_en: "YUANTA COMMERCIAL BANK CO., LTD",
    name_ch: "元大商業銀行股份有限公司",
  },
  {
    id: "382",
    name_en: "BANK OF COMMUNICATIONS (HONG KONG) LIMITED",
    name_ch: "交通銀行(香港)有限公司",
  },
  {
    id: "383",
    name_en: "CHINA ZHESHANG BANK CO., LTD.",
    name_ch: "浙商銀行股份有限公司",
  },
  {
    id: "385",
    name_en: "PING AN BANK CO., LTD.",
    name_ch: "平安銀行股份有限公司",
  },
  {
    id: "386",
    name_en: "HUA XIA BANK CO., LIMITED",
    name_ch: "華夏銀行股份有限公司",
  },
  {
    id: "868",
    name_en: "CLS BANK INTERNATIONAL",
    name_ch: "",
  },
  {
    id: "387",
    name_en: "ZA BANK LIMITED",
    name_ch: "眾安銀行有限公司",
  },
  {
    id: "388",
    name_en: "LIVI VB LIMITED",
    name_ch: "",
  },
  {
    id: "389",
    name_en: "MOX BANK LIMITED",
    name_ch: "",
  },
  {
    id: "390",
    name_en: "WELAB BANK LIMITED",
    name_ch: "匯立銀行有限公司",
  },
  {
    id: "391",
    name_en: "FUSION BANK LIMITED",
    name_ch: "富融銀行有限公司",
  },
  {
    id: "392",
    name_en: "PING AN ONECONNECT BANK (HONG KONG) LIMITED",
    name_ch: "平安壹賬通銀行(香港)有限公司",
  },
  {
    id: "393",
    name_en: "ANT BANK (HONG KONG) LIMITED",
    name_ch: "螞蟻銀行(香港)有限公司",
  },
  {
    id: "395",
    name_en: "AIRSTAR BANK LIMITED",
    name_ch: "天星銀行有限公司",
  },
];
