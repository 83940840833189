import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";

import { useDecodeToken } from "../../hooks/useDecodeToken";
import { useFetch } from "../../hooks/useFetch";

import styles from "../../styles/MemberInfo.module.scss";

function ApplyMentor() {
  const userInfo = useDecodeToken();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [memberNumber, setMemberNumber] = useState(0);
  const [mentorList, setMentorList] = useState([]);
  const [applyMentorList, setApplyMentorList] = useState([]);

  const [statusFilterType, setStatusFilterType] = useState("");
  const [dateFilterType, setDateFilterType] = useState("");

  useEffect(() => {
    const limit = 5;
    const start = (pageNumber - 1) * limit;

    if (userInfo) {
      const GetMentor = async () => {
        try {
          const res = await useFetch(`${process.env.REACT_APP_STRAPI}/mentor-profiles?`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.jwtToken}`,
            },
          });

          if (!res.error) {
            setMemberNumber(res.data.length);
          } else {
            toast.error(res.error.message);
          }
        } catch (e) {
          console.error(e);
        }
      };

      const GetApplyMentor = async () => {
        try {
          const res = await useFetch(
            `${process.env.REACT_APP_STRAPI}/mentor-profiles?pagination[start]=${start}&pagination[limit]=${limit}&sort[0]=id:asc&populate[0]=User_profile.Mentor.Mentor_schedules`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.jwtToken}`,
              },
            }
          );

          if (!res.error) {
            setMentorList(res.data);
            setApplyMentorList(res.data);
          } else {
            toast.error(res.error.message);
          }
        } catch (e) {
          console.error(e);
        }
      };

      GetMentor();
      GetApplyMentor();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (statusFilterType === "") {
      setApplyMentorList(mentorList);
    }
  }, [mentorList, statusFilterType]);

  useEffect(() => {
    if (userInfo && statusFilterType !== "" && dateFilterType !== "") {
      if (dateFilterType === "last7") {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        const last7 = date.toISOString();

        const GetApplyMentor = async () => {
          try {
            const res = await useFetch(
              `${process.env.REACT_APP_STRAPI}/mentor-profiles?populate[0]=User_profile.Mentor.Mentor_schedules&filters[createdAt][$gte]=${last7}&filters[status][$eq]=${statusFilterType}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userInfo.jwtToken}`,
                },
              }
            );

            if (!res.error) {
              setApplyMentorList(res.data);
            } else {
              toast.error(res.error.message);
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetApplyMentor();
      } else if (dateFilterType === "last14") {
        const date = new Date();
        date.setDate(date.getDate() - 14);
        const last14 = date.toISOString();

        const GetApplyMentor = async () => {
          try {
            const res = await useFetch(
              `${process.env.REACT_APP_STRAPI}/mentor-profiles?populate[0]=User_profile.Mentor.Mentor_schedules&filters[createdAt][$gte]=${last14}&filters[status][$eq]=${statusFilterType}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userInfo.jwtToken}`,
                },
              }
            );

            if (!res.error) {
              setApplyMentorList(res.data);
            } else {
              toast.error(res.error.message);
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetApplyMentor();
      } else if (dateFilterType === "last30") {
        const date = new Date();
        date.setDate(date.getDate() - 30);
        const last30 = date.toISOString();

        const GetApplyMentor = async () => {
          try {
            const res = await useFetch(
              `${process.env.REACT_APP_STRAPI}/mentor-profiles?populate[0]=User_profile.Mentor.Mentor_schedules&filters[createdAt][$gte]=${last30}&filters[status][$eq]=${statusFilterType}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userInfo.jwtToken}`,
                },
              }
            );

            if (!res.error) {
              setApplyMentorList(res.data);
            } else {
              toast.error(res.error.message);
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetApplyMentor();
      } else if (dateFilterType === "last60") {
        const date = new Date();
        date.setDate(date.getDate() - 60);
        const last60 = date.toISOString();

        const GetApplyMentor = async () => {
          try {
            const res = await useFetch(
              `${process.env.REACT_APP_STRAPI}/mentor-profiles?populate[0]=User_profile.Mentor.Mentor_schedules&filters[createdAt][$gte]=${last60}&filters[status][$eq]=${statusFilterType}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userInfo.jwtToken}`,
                },
              }
            );

            if (!res.error) {
              setApplyMentorList(res.data);
            } else {
              toast.error(res.error.message);
            }
          } catch (e) {
            console.error(e);
          }
        };
        GetApplyMentor();
      }
    } else if (userInfo && statusFilterType !== "") {
      const GetApplyMentor = async () => {
        try {
          const res = await useFetch(
            `${process.env.REACT_APP_STRAPI}/mentor-profiles?populate[0]=User_profile.Mentor.Mentor_schedules&filters[status][$eq]=${statusFilterType}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.jwtToken}`,
              },
            }
          );

          if (!res.error) {
            setApplyMentorList(res.data);
          } else {
            toast.error(res.error.message);
          }
        } catch (e) {
          console.error(e);
        }
      };
      GetApplyMentor();
    } else if (userInfo && dateFilterType !== "") {
      toast.error("先選擇申請Status");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilterType, dateFilterType]);

  const renderApplyMentorList = () => {
    return applyMentorList.map((list: any) => {
      return (
        <div
          className={styles.memberCard}
          key={list.id}
          onClick={() => navigate(`/applyDetail/${list.id}`)}
        >
          {list.attributes.User_profile.data.attributes.first_name}{" "}
          {list.attributes.User_profile.data.attributes.last_name}'s Application
          {(statusFilterType === "on_board" || statusFilterType === "") && (
            <span>{list.attributes.approvedBy}</span>
          )}
        </div>
      );
    });
  };

  const handleFilterChange = (e: any, type: string) => {
    const name = e.target.name;
    if (type === "status") {
      setStatusFilterType((prev) => (prev === name ? "" : name));
    }
    if (type === "date") {
      setDateFilterType((prev) => (prev === name ? "" : name));
    }
  };

  const filter = () => {
    return (
      <>
        <Toaster position="top-right" reverseOrder={false} />
        <div className={styles.applyStatus}>
          <div className={styles.filterType}>申請Status</div>
          <div className={styles.filterBox}>
            <label>
              <input
                type="checkbox"
                id="pending"
                name="pending"
                checked={statusFilterType === "pending"}
                onChange={(e) => handleFilterChange(e, "status")}
              />
              Pending
            </label>
            <label>
              <input
                type="checkbox"
                id="on_board"
                name="on_board"
                checked={statusFilterType === "on_board"}
                onChange={(e) => handleFilterChange(e, "status")}
              />
              On Board
            </label>
            <label>
              <input
                type="checkbox"
                id="rejected"
                name="rejected"
                checked={statusFilterType === "rejected"}
                onChange={(e) => handleFilterChange(e, "status")}
              />
              Rejected
            </label>
            <label>
              <input
                type="checkbox"
                id="false"
                name="false"
                checked={statusFilterType === "false"}
                onChange={(e) => handleFilterChange(e, "status")}
              />
              Not Submitted
            </label>
          </div>
        </div>

        <div className={styles.applyDate}>
          <div className={styles.filterType}>申請期</div>
          <div className={styles.filterBox}>
            <label>
              <input
                type="checkbox"
                id="last7"
                name="last7"
                checked={dateFilterType === "last7"}
                onChange={(e) => handleFilterChange(e, "date")}
              />
              最近7日
            </label>
            <label>
              <input
                type="checkbox"
                id="last14"
                name="last14"
                checked={dateFilterType === "last14"}
                onChange={(e) => handleFilterChange(e, "date")}
              />
              最近14日
            </label>
            <label>
              <input
                type="checkbox"
                id="last30"
                name="last30"
                checked={dateFilterType === "last30"}
                onChange={(e) => handleFilterChange(e, "date")}
              />
              最近30日
            </label>
            <label>
              <input
                type="checkbox"
                id="last60"
                name="last60"
                checked={dateFilterType === "last60"}
                onChange={(e) => handleFilterChange(e, "date")}
              />
              最近60日
            </label>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={styles.filter}>{filter()}</div>
      <div className={styles.subTitle}>
        <span>申請人名稱</span>
        <span>批核人名稱</span>
      </div>
      {renderApplyMentorList()}
      <div className={styles.footer}>
        {statusFilterType === "" && dateFilterType === "" && (
          <>
            <div onClick={() => setPageNumber(pageNumber - 1)}>
              {pageNumber === 1 ? (
                <span style={{ color: "white" }}>上一批</span>
              ) : (
                <span>上一批</span>
              )}
            </div>

            <div>第 {pageNumber} 頁</div>

            <div onClick={() => setPageNumber(pageNumber + 1)}>
              {mentorList.length % 5 === 0 && mentorList.length * pageNumber < memberNumber ? (
                <span>下一批</span>
              ) : (
                <span style={{ color: "white" }}>下一批</span>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ApplyMentor;
