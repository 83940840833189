import React from "react";
import styles from "../../styles/MemberInfo.module.scss";
import { useNavigate } from "react-router-dom";
import { IoReturnUpBack } from "react-icons/io5";
import { LiaHomeSolid } from "react-icons/lia";
import PlatformConfig from "../../components/platformConfig/PlatformConfig";

function PlatformConfigPage() {
  const navigate = useNavigate();

  return (
    <div className={styles.memberInfo}>
      <div className={styles.header}>
        <div onClick={() => navigate(-1)}>
          <IoReturnUpBack />
        </div>
        <div onClick={() => navigate("/")}>
          <LiaHomeSolid />
        </div>
      </div>

      <div className={styles.subTitle}></div>

      <div className={styles.memberList}>
        <PlatformConfig />
      </div>
    </div>
  );
}

export default PlatformConfigPage;
